import * as React from 'react';

import { Listbox, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';

import DateTimeRangePicker, { ISelectionRange } from 'Components/DateRange/DateRange';
import { CustomMenu, MenuItems } from 'Components/Dropdown/Dropdown';
import Constants from 'Libraries/Constants';
import { BsFilter } from 'react-icons/bs';
import { CgSearch } from 'react-icons/cg';
import { useDebounce } from 'Hooks/UseDebounce';
import ApiRequest from 'Services/ApiRequest';
import { IScreenFilter } from 'Libraries/Interfaces';
import { useSelector } from 'react-redux';
import { statusEnum } from 'Libraries/Enums';

interface IProps {
  selectionRange: ISelectionRange;
  setSelectionRange: (arg: ISelectionRange) => void;
  onChangeSelection: (arg: any) => void;
  search: string;
  setSearch: (s: string) => void;
  onEnterPress: () => void;
  filters: { [key: string]: string; };
  onReset: () => void;
}

export function MyCombobox({ arr, getSelected, placeholder, className, isReset }: { isReset: boolean; arr: string[]; getSelected: (s: string) => void; placeholder: string; className?: string; }): JSX.Element {

    const [selected, setSelected] = React.useState('');

    React.useEffect(() => {
      if (selected){
        getSelected(selected);
      }
    }, [selected]);

    React.useEffect(() => {
      if (isReset){
        setSelected('');
      }
    }, [isReset]);
  
    return (
        <div className={`w-48 ${className}`}>
          <Listbox value={selected} onChange={setSelected}>
            <div className="relative">
              <Listbox.Button className="relative w-full cursor-pointer rounded-full h-7 bg-white ring-1 ring-inset ring-gray-200 py-1 pl-3 pr-10 text-left focus:outline-none text-xs">
                <span className="block truncate">{selected ? selected : placeholder }</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-4 w-4 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={React.Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-xs">
                  { arr.length === 0 ? (
                    <Listbox.Option className="relative rounded-sm select-none py-1 text-center text-black" value={'Data Not Found'} disabled>
                      <span className="block truncate font-normal">
                        Data Not Found
                      </span>
                    </Listbox.Option>
                  ) : arr.map((str: string, personIdx: number) => (
                    <Listbox.Option
                      key={personIdx}
                      className={({ active }) =>
                        `relative cursor-pointer rounded-sm select-none py-2 pl-3 pr-4 hover:bg-[#EAEAEA] bg-opacity-30 ${
                          active ? 'bg-[#EAEAEA] text-black' : 'text-black'
                        }`
                      }
                      value={str}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? 'font-medium' : 'font-normal'
                            }`}
                          >
                            {str}
                          </span>
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
      )
}

export const DashboardFilter: React.FC<IProps> = ({ 
  selectionRange, setSelectionRange, onChangeSelection, filters, search, setSearch, onEnterPress, onReset
}) => {
  const companies = useSelector((state: any) => state['loginReducer'].user.assigned_companies?.map((c: any, ind: number) => {
    return { id: ind + 1, name: c?.company_name };
  })) ?? [];
  const statuses = Object.values(statusEnum).filter((f) => typeof(f) === 'string').map((key: any, ind: number) => {
    return { id: ind + 1, name: key?.split("_")?.join(" ") };
  });

  return (
    <div className='flex items-center justify-start h-20 border-b border-primary-grey px-4 sm:px-6 lg:px-8'>
        { Object.entries(filters).map(([key, value]: [string, string], index: number) => (
          <CustomMenu onRemove={() => onChangeSelection({ [key]: "" })} value={value} title={key.charAt(0).toUpperCase() + key.slice(1)} key={index}>
            <MenuItems arr={key === 'company' ? companies : statuses} isSmallIcon={true} onClick={(e: any, item)=> {
              e.stopPropagation();
              onChangeSelection({ [key]: item.name });
            }} />
          </CustomMenu>
        )) }                     
        <DateTimeRangePicker
            setSelectionRange={(arg: ISelectionRange | undefined) => {
              if (arg !== undefined) {
                setSelectionRange(arg);
              } else {
                setSelectionRange({
                  startDate: undefined,
                  endDate: undefined,
                  key: 'selection',
                });
              }
            }}
            selectionRange={selectionRange}
        />
        <div className="relative ml-4">
          <input type="text" name="search-filter" id="active-search-filter" value={search} onChange={(e) => setSearch(e.target.value)} className="w-56 block rounded-full border-0 pl-4 pr-8 h-7 text-black bg-white ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 text-xs" placeholder="Search" />
          <div className="pointer-events-auto absolute inset-y-0 right-0 flex items-center pr-3">
            <CgSearch className="h-4 w-4 cursor-pointer text-gray-400" onClick={() => onEnterPress()} />
          </div>
        </div>
        <button onClick={onReset} className="inline-flex ml-3 items-center justify-center px-4 rounded-full bg-primary-blue-dark hover:bg-opacity-60 transition-all duration-200 h-7 text-xs font-semibold text-white shadow-sm focus-visible:outline">
            Reset
        </button>
        <div className='ml-auto hidden'>
          <BsFilter className="h-6 w-6 cursor-pointer" />
        </div>
    </div>
  );
}

export const SearchFilter: React.FC<{ arr: IScreenFilter[]; setFilters: (arg: { key: string; value: string; }) => void; onSearch: () => void; }> = ({ arr, setFilters, onSearch }) => {

    const [companies, setCompanies] = React.useState<string[]>([]);
    const [search, setSearch] = React.useState<string>('');
    const [isReset, setIsReset] = React.useState<boolean>(false);
    const [selectedFirstDropdown, setSelectedFirstDropdown] = React.useState<IScreenFilter>();
    
    const searchQuery = useDebounce(search, 500);

    async function getCompanies(): Promise<void> {
      const res = await ApiRequest.getCompanies();
      setCompanies(res.data?.company_names ?? []);
    }

    React.useEffect(() => {
      if (searchQuery && selectedFirstDropdown?.key) {
        setFilters({ key: selectedFirstDropdown?.key, value: searchQuery.toString() });
      }
    }, [searchQuery, selectedFirstDropdown?.key]);

    React.useEffect(() => {
      getCompanies();
    }, []);
    
    return (
        <div className='flex items-center justify-end mb-4'>
            <MyCombobox 
              isReset={isReset} placeholder="Select Filter" arr={arr.map(a => a.value)} 
              getSelected={(s) => { 
                const obj: IScreenFilter | any = Constants.screenFilters.find(f => f.value === s);
                setSelectedFirstDropdown(obj);
              }} 
            />
            { selectedFirstDropdown?.isDropdown && ( <MyCombobox isReset={isReset} className="ml-4" placeholder={`Select ${selectedFirstDropdown?.value}`} arr={companies} getSelected={(s) => setFilters({ key: selectedFirstDropdown?.key, value: s })} /> )}
            { selectedFirstDropdown?.id && !selectedFirstDropdown?.isDropdown && (
              <div className="relative ml-4">
                <input type="text" name="search-filter" id="search-filter" onKeyPress={(e) =>  {
                  if (e.key === 'Enter') {
                    onSearch();
                  }
                }} value={search} onChange={(e) => setSearch(e.target.value)} className="w-48 block rounded-full border-0 pl-4 pr-8 h-7 text-black bg-white ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 text-xs" placeholder="Search" />
                <div className="pointer-events-auto absolute inset-y-0 right-0 flex items-center pr-3">
                  <CgSearch className="h-4 w-4 cursor-pointer text-gray-400" onClick={() => search && onSearch()} />
                </div>
              </div>
            )}
            <div className='ml-4'>
              <button disabled={!selectedFirstDropdown?.id} onClick={() => {
                setIsReset(true);
                setFilters({ key: '', value: '' });
                setSearch('');
              }} className='px-4 py-1.5 inline-flex items-center hover:bg-opacity-60 transition-all duration-200 justify-center h-6 rounded-full bg-primary-blue-dark text-xs text-white'>
                Reset
              </button>
            </div>
        </div>
    );
}

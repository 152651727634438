import React, {useEffect, useState} from 'react';

import withAuthentication from "Hoc/WithAuthentication";

import Modal from "Components/Modal/Modal";
import PaginatedRecord from "Components/PaginatedRecord/PaginatedRecord";
import Constants from "Libraries/Constants";
import { Paths } from "Libraries/Route";
import CommonMethodsUI from "Libraries/CommonMethodsUI";
import {Button} from "Components/Button/Button";
import RowClickModal from "Components/RowClickModal/RowClickModal";
import CreateRecord from "Components/CreateRecord/CreateRecord";
import MasterLayout from 'Hoc/MasterLayout';
import DocumentTitle from 'Components/DocumentTitle/DocumentTitle';
import { CustomTable } from 'Components/CustomTable/CustomTable';
import ApiRequest from 'Services/ApiRequest';
import { ICompany } from 'Libraries/Interfaces';

const CompanyScreen:React.FC<any> = ({ user }) => {
    
    const [addCompanyModal, setAddCompanyModal] = useState<{ flag: boolean, key: string }>({flag: false, key: ''});
    const [openRowModal, setOpenRowModal] = useState<{ data: any, flag: boolean | undefined, isStatusLog: boolean | undefined }>({data: null, flag: false, isStatusLog: false});
    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [total, setTotal] = React.useState<number>(0);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [list, setList] = React.useState<{ copy: ICompany[], original: ICompany[] }>({ copy: [], original: [] });

    const getCompaniesList = React.useCallback(async () => {
        try {
            const res = await ApiRequest.getCompaniesList();
            setTotal(res.data.count);
            setList({ copy: res.data?.results ?? [], original: res.data?.results ?? [] });
            setLoading(false);
        }
        catch(e) {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        getCompaniesList();
    },[getCompaniesList]);

    return (
        <MasterLayout mainTitle='Companies' mainClassName="overflow-hidden" className='px-4 sm:px-6 lg:px-8' userEmail={user?.email as string}>
           <DocumentTitle title='Companies'> 
                <Button
                    onClick={()=> setAddCompanyModal({ ...addCompanyModal, flag: true })} title={'Add Company'}
                    className="px-4 hidden inline-flex items-center hover:bg-opacity-60 transition-all duration-200 justify-center h-7 float-right rounded-full bg-primary-blue-dark text-sm text-white mb-3"
                />
                <div className="flow-root mt-3 transition-all max-h-screen duration-200 clear-both clearfix">
                    <CustomTable 
                        maxHeight="max-h-screen" loading={loading} emptyList={list.original.length === 0}
                        headers={{ data: Constants.CompanyHeaders().map((h) => h.name), hasIcons: false }}
                    >
                        {list.original.map((company: ICompany, ind: number) => (
                            <tr 
                                className={'cursor-pointer hover:bg-[#EAEAEA]'} key={company.company_name}
                                // onClick={()=> setOpenRowModal({data: company, flag: true, isStatusLog: false})}
                            >
                                {Constants.CompanyHeaders(company).map((item,i) => (
                                    <td className={CommonMethodsUI.classNames(
                                        ind !== list.original?.length - 1 ? 'border-b border-gray-200' : '',
                                        'whitespace-nowrap py-4 pl-4 text-sm font-normal text-gray-900'
                                    )} key={i}>
                                        {i <= 2 ? CommonMethodsUI.noDataAvailable(item?.data as any) : CommonMethodsUI.answerBoolean(item.data)}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </CustomTable>
                </div>

                {/* ============= Pagination ==============*/}

                <PaginatedRecord
                    totalPages={Math.ceil(total / 10)} total={total} listLength={list.original.length}
                    onPageChange={(p) => setCurrentPage(p)} currentPage={currentPage}
                    onDisplayChange={(n: number) => setList({ ...list, original: list.copy.slice(0, n) })} 
                    perPage={10}
                />

               {openRowModal.flag &&
               <Modal open={openRowModal.flag} setOpen={(e: any) => setOpenRowModal({...openRowModal, flag: e})}
                      isSmall={false}>
                   <RowClickModal openRowModal={openRowModal.data}
                                  isStatusLog={openRowModal.isStatusLog}
                                  screen={'company'}
                                  setOpen={(e: boolean | undefined) => setOpenRowModal({...openRowModal, flag: e})}/>
               </Modal>
               }

               {addCompanyModal.flag &&
               <Modal open={addCompanyModal.flag } setOpen={(e: any) => setAddCompanyModal({...addCompanyModal , flag: e })}  isSmall={true}>
                   <CreateRecord  />
               </Modal>
               }

            </DocumentTitle>
        </MasterLayout>
    )
};

export default withAuthentication(CompanyScreen, Paths.login, 2);

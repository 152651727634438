import * as React from 'react';

import PaginatedRecord from 'Components/PaginatedRecord/PaginatedRecord';
import ExposuresModal from 'Components/ExposuresModal/ExposuresModal';
import { MdDelete } from 'react-icons/md';
import ApiRequest from 'Services/ApiRequest';
import { IPendingVersionListItem, IUserProfile } from 'Libraries/Interfaces';
import Methods from 'Libraries/CommonMethodsUI';
import { CustomTable } from 'Components/CustomTable/CustomTable';
import { ConfirmModal, DeleteOrAddPremiumModal } from 'Components/Modal/Modal';
import { toast } from 'react-toastify';
import { RolesEnum } from 'Libraries/Enums';

interface IProps {
    user: IUserProfile;
    controlNumber: string;
}

const PendingVersions: React.FC<IProps> = ({ controlNumber, user }) => {
    
    const [checked, setChecked] = React.useState<number[]>([]);
    const [selected, setSelected] = React.useState<{ item: IPendingVersionListItem | undefined; flag: boolean; }>({ item: undefined, flag: false });
    const [exposurePanel, setExposurePanel] = React.useState<boolean>(false);
    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [total, setTotal] = React.useState<number>(1);
    const [loading, setLoading] = React.useState<boolean>(true);

    const [pendingVersionList, setPendingVersionList] = React.useState<{ copy: IPendingVersionListItem[], original: IPendingVersionListItem[] }>({ copy: [], original: [] });

    const getApprovedVersions = React.useCallback(async (f: boolean) => {
        if (!controlNumber) {
            setLoading(false);
            return;
        }

        setChecked([]);
        setLoading(f);
        const params: string = `?control_number=${controlNumber}&page_size=10&page=${currentPage}`;
        try {
            const res = await ApiRequest.getPendingSubmissionVersions(params);
            setTotal(res.data.count);
            setPendingVersionList({ copy: res.data.results, original: res.data.results });
            setLoading(false);
        }
        catch(e) {
            setLoading(false);
        }
    }, [controlNumber, currentPage]);

    React.useEffect(() => {
        getApprovedVersions(true);
    }, [getApprovedVersions]);

    const archiveSelectedVersions = () => {};

    return (
        <React.Fragment>
            <div className='h-5'></div>
            <div className={`flow-root transition-all max-h-screen duration-200 relative`}>
                { checked.length > 0 ? (
                    <DeleteOrAddPremiumModal status={{ id: 0, value: '' }} onCloseModal={() => console.log()} onConfirm={() => archiveSelectedVersions()}>
                        <MdDelete className='cursor-pointer transition-all z-20 top-2 left-2 absolute duration-200 ease-in-out hover:text-black text-2xl text-primary-grey-10' />
                    </DeleteOrAddPremiumModal>
                ) : null}
                <CustomTable 
                    maxHeight="max-h-screen" loading={loading} emptyList={pendingVersionList.original.length === 0}
                    headers={{ data: ["Serial #", "Created On", "Control Number", "Version", "Attachments"], hasIcons: false, modified: undefined }}
                >
                    {pendingVersionList.original.map((item: IPendingVersionListItem, ind: number) => (
                        <tr key={ind} className={`hover:bg-[#EAEAEA] rounded-sm bg-transparent transition-all cursor-pointer duration-200 border-b border-primary-grey`}>
                            {/* <td className="whitespace-nowrap pt-2 px-3 w-14" onClick={(e) => e.stopPropagation()}>
                                <CustomCheckBox checked={checked.includes(item.submission_id)} setChecked={(f: boolean) => {
                                    setChecked(checked.includes(item.submission_id) ? checked.filter((f) => f !== item.submission_id) : [ ...checked, item.submission_id ]);
                                    setSelected({ flag: false, item });
                                }} forHead={false} />
                            </td> */}
                            <td onClick={(e) => {
                                e.stopPropagation();
                            }} className={`whitespace-nowrap py-4 w-1/6 px-3 underline text-xs text-[#155D9C] cursor-pointer font-semibold`}>{ind + 1}</td>
                            <td className={`whitespace-nowrap py-4 w-1/6 px-3 text-xs text-[#343A40]`}>{Methods.convertUTCtoLocalDate(item?.created_on) ?? 'N/A'}</td>
                            <td className={`whitespace-nowrap py-4 px-3 text-xs w-1/6`}>
                                <ConfirmModal isDisabledConfirm={user.role !== RolesEnum[RolesEnum.CAT_MODELER]} onConfirm={async () => {
                                    try {
                                        await ApiRequest.approveAsControlNumber({ pending_submission_version_id: item.id });
                                        const filtered = pendingVersionList.original.filter((f) => f.id !== item.id);
                                        setPendingVersionList({ copy: filtered, original: filtered });
                                        toast.success("New submission created successfully.");
                                    }
                                    catch (err) {}
                                }} message='Are you sure you want to create as new control number' onCloseModal={() => console.log()}>
                                    <button disabled={user.role !== RolesEnum[RolesEnum.CAT_MODELER]} className='py-1 rounded-full bg-primary-green hover:bg-opacity-60 transition-all w-24 inline-flex items-center justify-center duration-200 text-sm text-white'>
                                        Create
                                    </button>        
                                </ConfirmModal>
                            </td>
                            <td className={`whitespace-nowrap py-4 px-3 text-xs w-1/6`}>
                                <ConfirmModal isDisabledConfirm={user.role !== RolesEnum[RolesEnum.CAT_MODELER]} onConfirm={async () => {
                                    try {
                                        await ApiRequest.approvePendingVersion({ pending_submission_version_id: item.id });
                                        const filtered = pendingVersionList.original.filter((f) => f.id !== item.id);
                                        setPendingVersionList({ copy: filtered, original: filtered });
                                        toast.success("New version created successfully.");
                                    }
                                    catch (err) {}
                                }} message='Are you sure you want to approve as new version' onCloseModal={() => console.log()}>
                                    <button disabled={user.role !== RolesEnum[RolesEnum.CAT_MODELER]} className='py-1 rounded-full bg-primary-red hover:bg-opacity-60 transition-all w-28 inline-flex items-center justify-center duration-200 text-sm text-white'>
                                        Approve
                                    </button>
                                </ConfirmModal>
                            </td>
                            <td className={`whitespace-nowrap py-4 px-3 text-xs w-1/6`}>
                                <button onClick={(e) => {
                                    setSelected({ item, flag: selected.flag ? true : false });
                                    e.stopPropagation();
                                    setExposurePanel(true);
                                    setSelected({ item, flag: true });
                                }} className='px-4 py-1 rounded-full bg-primary-green hover:bg-opacity-60 transition-all duration-200 text-sm text-white'>Details</button>
                            </td>
                        </tr>
                    ))}
                </CustomTable>
            </div>

            <PaginatedRecord
                totalPages={Math.ceil(total / 10)} total={total} listLength={pendingVersionList.original.length}
                onPageChange={(p) => setCurrentPage(p)} currentPage={currentPage} perPage={10}
                onDisplayChange={(n: number) => setPendingVersionList({ ...pendingVersionList, original: pendingVersionList.copy.slice(0, n) })}
            />

            <ExposuresModal open={exposurePanel} setOpen={setExposurePanel} selectedVersion={undefined} isPendingSubmissionVersion={selected.item} controlNumber={controlNumber} />
        </React.Fragment>
    );
}

export default PendingVersions;
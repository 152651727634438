import * as React from 'react';

import { ComputerDesktopIcon, MapPinIcon } from "@heroicons/react/24/outline";
import ApiRequest from 'Services/ApiRequest';
import { SpinnerLoader } from 'Components/Loader/Loader';
import Methods from 'Libraries/CommonMethodsUI';
import { IDashboardCardData } from 'Libraries/Interfaces';

interface IMainCards {
    className: string;
    params: string;
}
 
const MainCards: React.FC<IMainCards> = ({ className, params }) => {

    const [loading, setLoading] = React.useState<boolean>(true);
    const [data, setData] = React.useState<IDashboardCardData>({ accounts_processed: 0, average_locations: 0, total_tiv: 0, unique_accounts: 0 });

    const getDashboardCounts = React.useCallback(async () => {
        try {
            setLoading(true);
            const res = await ApiRequest.getDashboardCounts(`?${params.slice(1)}`);
            setData(res.data);
            setLoading(false);
        }
        catch(e) {
            setLoading(false);
        }
    }, [params]);

    React.useEffect(() => {
        getDashboardCounts();
    }, [getDashboardCounts]);    

    return(
        <div className={className}>
            <div className='flex items-center justify-start xl:justify-around lg:justify-around rounded-xl shadow p-4 min-w-full overflow-x-auto remove-scrollbar'>
                {[
                    { name: 'Total TIV', value: Methods.numberFormatter(data?.total_tiv), Icon: ComputerDesktopIcon }, 
                    { name: 'Accounts Processed', value: data?.accounts_processed, Icon: ComputerDesktopIcon }, 
                    { name: 'Average Locations', value: data?.average_locations, Icon: MapPinIcon }, 
                    { name: 'Unique Accounts', value: data?.unique_accounts, Icon: ComputerDesktopIcon }
                ].map(({ Icon, name, value }, i) => (
                    <div className='flex items-center min-w-[250px]' key={i}>
                        <div className='flex-shrink-0 h-14 w-14 flex flex-col items-center justify-center rounded-full bg-green-100 mr-6'>
                            <Icon className='h-9 w-9 text-green-600 font-medium' />
                        </div>
                        <div className='flex flex-col'>
                            <span className='block text-gray-400 text-base m-0 h-6'>{ name }</span>
                            <h6 className='block text-black text-xl font-bold m-0 h-7'>
                                {loading ? <SpinnerLoader adjustment={false} enhance="text-black text-xs mt-1" /> : value}
                            </h6>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default MainCards;
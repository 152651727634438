import * as React from 'react';

import DocumentTitle from 'Components/DocumentTitle/DocumentTitle';
import MasterLayout from 'Hoc/MasterLayout';
import { useLocation, useParams } from 'react-router-dom';
import { Paths } from 'Libraries/Route';
import ApiRequest from 'Services/ApiRequest';
import { SpinnerLoader } from 'Components/Loader/Loader';
import GoogleMap from 'Components/Maps/GoogleMap';
import Methods from 'Libraries/CommonMethodsUI';
import { Tabs, TabWithRemove } from 'Components/Tabs/Tabs';
import withAuthentication from 'Hoc/WithAuthentication';
import HeatMap, { ChoroplethHeatMap} from 'Components/Maps/HeatMap';
import { BarChartSection, GeoCodeConfidenceSection, GuageChartSection, MappingDoughnutSection, TIVChartSection } from './ReportElements';
import { useFetchCurrentSubmission } from 'Hooks/UseFetch';
import Constants from 'Libraries/Constants';
import { IReportData } from 'Libraries/Interfaces';
import { useDispatch } from 'react-redux';
import { setAppLoaderAction } from 'Redux/Action';
import { SwitchShortToggle } from 'Components/SwitchToggle/SwitchToggle';

var barChartMode: string = "Country";
var barChartMode1: string = "Country";
var filters: { key: string; title: string; verify: string; filter: string; }[] = [];

function ReportAnalyticsBE({ user, navigate }: any): JSX.Element {

    const dispatch = useDispatch();

    const { controlnumber, version } = useParams<{ controlnumber: string; version: string; }>();
    const { search } = useLocation();

    const [mapType, setMapType] = React.useState<number>(2);
    const [reportData, setReportData] = React.useState<{ [key: string]: any; } | undefined>(undefined);
    const [dataCoverageByGeography, setDataCoverageByGeography] = React.useState<any[]>([]);
    const [dataCoverageByGeography1, setDataCoverageByGeography1] = React.useState<any[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [isIgnoreZeroTivLocations, setIsIgnoreZeroTivLocations] = React.useState<boolean>(false);
    const [byLocationOrTiv, setByLocationOrTiv] = React.useState<boolean>(false);

    const [constructions, setConstructions] = React.useState<{ [key: string]: any; }>({});
    const [occupancies, setOccupancies] = React.useState<{ [key: string]: any; }>({});
    const [geoCodeConfidence, setGeoCodeConfidence] = React.useState<{ [key: string]: any; }>({});

    const { submission } = useFetchCurrentSubmission(controlnumber as string);

    const initialRun = React.useCallback(async () => {
        const f: { key: string; title: string; verify: string; filter: string; }[] = localStorage.getItem("report-filters") ? JSON.parse(localStorage.getItem("report-filters") as string) : [];

        if (f.length > 0) {
            setLoading(true);
            filters = f;
            const persistFilters = f.reverse();
            let str = '';
            persistFilters.forEach((f) => {
                str = str + (f.filter?.includes('&') ? f.filter : `&${f.key}=${f.filter}`);
            });
            const constructionFilter = persistFilters.find(f => f.key === "construction_code" && f.verify !== 'bar');
            const occupancyFilter = persistFilters.find(f => f.key === "occupancy_code" && f.verify !== 'bar');
            const geoCodeFilter = persistFilters.find(f => f.key === "geocode_confidence" && f.verify !== 'bar');

            const params: string = `?version=${version}&control_number=${controlnumber}`;
            const params1 = `?version=${version}&control_number=${controlnumber}&data_by=${Object.entries(Constants.getDataByOptions).find(([_, val]) => val === barChartMode)?.[0]}`;
            const res: any = await ApiRequest.getDTReportJson(params);
            const result: IReportData = res?.data?.data_transparency_report_json;

            const apis = [
                ApiRequest.reportCoverageData(params+str), ApiRequest.reportMapData(params+str), 
                ApiRequest.reportDataCompleteness(params+str), ApiRequest.reportDataQuality(params+str),
                ApiRequest.reportGeoCodeData(geoCodeFilter ? params : params+str),
                ApiRequest.reportConstructionsData(constructionFilter ? params : params+str),
                ApiRequest.reportOccupancyData(occupancyFilter ? params : params+str),
                ApiRequest.reportDataCoverageByGeography(filters.find(f => f.verify === 'bar') ? params1 : params1 + str )
            ];
            
            const response = await Promise.all(apis);

            setReportData({
                coverages: response[0].data, mapData: response[1].data?.data ?? [],
                dataCompleteness: response[2].data, dataQuality: response[3].data, dataMapped: result?.dataMapped ?? undefined
            });
            setGeoCodeConfidence(response[4]?.data?.data ?? {});
            setConstructions(response[5]?.data ?? {});
            setOccupancies(response[6]?.data ?? {});
            setDataCoverageByGeography(response[7]?.data?.data ?? []);
            setDataCoverageByGeography1(response[7]?.data?.data ?? []);

            setLoading(false);
        }
        else {
            getReportData([], true, false, false);
            getBarChartData([], barChartMode, false, false, false);
            getBarChartData([], barChartMode1, true, false, false);
        }
    }, []);

    React.useEffect(() => {
        initialRun();
        return () => {
            filters = [];
            setLoading(true);
        }
    }, [initialRun]);

    async function getReportData(filters: { key: string; title: string; verify: string; filter: string; }[], flag: boolean, ignoreZeroTivLocs: boolean, byLocOrTIV: boolean): Promise<void> {
        if (version && controlnumber) {
            try {
                let str = '';
                filters.forEach((f) => {
                    str = str + (f.filter?.includes('&') ? f.filter : `&${f.key}=${f.filter}`);
                });
                if (!str) {
                    setLoading(flag);
                }
                if (ignoreZeroTivLocs) {
                    str = str + `&ignore_zero_tiv=true`;
                }
                str = str + `&by_tiv=${byLocOrTIV}`;

                let constMappings, occMappings, geoCode;

                const params: string = `?version=${version}&control_number=${controlnumber}`;
                const res: any = await ApiRequest.getDTReportJson(params);
                const result: IReportData = res?.data?.data_transparency_report_json;

                const apis = [
                    ApiRequest.reportCoverageData(params+str), ApiRequest.reportMapData(params+str), 
                    ApiRequest.reportDataCompleteness(params+str), ApiRequest.reportDataQuality(params+str)
                ];

                if(!filters.find(f => f.key === 'geocode_confidence' && f.verify === 'geo') || filters.length === 0) {
                    geoCode = await ApiRequest.reportGeoCodeData(filters.find(f => f.key === 'geocode_confidence' && f.verify === 'geo') ? params : params+str);
                }
                if(!filters.find(f => f.key === 'construction_code' && f.verify === 'const') || filters.length === 0) {
                    constMappings = await ApiRequest.reportConstructionsData(filters.find(f => f.key === 'construction_code' && f.verify === 'const') ? params : params+str);
                }
                if(!filters.find(f => f.key === 'occupancy_code' && f.verify === 'occ') || filters.length === 0) {
                    occMappings = await ApiRequest.reportOccupancyData(filters.find(f => f.key === 'occupancy_code' && f.verify === 'occ') ? params : params+str);
                }
                
                const response = await Promise.all(apis);

                setReportData({
                    coverages: response[0].data, mapData: response[1].data?.data ?? [],
                    dataCompleteness: response[2].data, dataQuality: response[3].data, dataMapped: result?.dataMapped ?? undefined
                });
            
                if(!filters.find(f => f.key === 'construction_code' && f.verify === 'const') || filters.length === 0) {
                    setConstructions(constMappings?.data ?? {});
                }
                if(!filters.find(f => f.key === 'occupancy_code' && f.verify === 'occ') || filters.length === 0) {
                    setOccupancies(occMappings?.data ?? {});
                }
                if(!filters.find(f => f.key === 'geocode_confidence' && f.verify === 'geo') || filters.length === 0) {
                    setGeoCodeConfidence(geoCode?.data?.data ?? {});
                }
                
                setLoading(false);
                dispatch(setAppLoaderAction(false));
            }
            catch(e) {
                setLoading(false);
                dispatch(setAppLoaderAction(false));
            }
        }
    };

    async function getBarChartData(filters: { key: string; title: string; verify: string; filter: string; }[], mode: string, isCompare: boolean, ignoreZeroTivLocs: boolean, byLocOrTIV: boolean): Promise<void> {
        if (version && controlnumber) {
            let str = '';
            filters.forEach((f) => {
                str = str + (f.filter?.includes('&') ? f.filter : `&${f.key}=${f.filter}`);
            });
            if (ignoreZeroTivLocs) {
                str = str + `&ignore_zero_tiv=true`;
            }
            str = str + `&by_tiv=${byLocOrTIV}`;

            let params: string = `?version=${version}&control_number=${controlnumber}&data_by=${Object.entries(Constants.getDataByOptions).find(([_, val]) => val === mode)?.[0]}${str}`;
        
            // const isCondition: boolean = (!filters.find(f => f.verify === 'bar') && !isCompare) || isCompare;
            // if (isCondition) {
                const response = await ApiRequest.reportDataCoverageByGeography(params);
                if (isCompare) {
                    setDataCoverageByGeography1(response.data?.data ?? []);
                }
                else {
                    setDataCoverageByGeography(response.data?.data ?? []);
                }
            // }
        }
    };

    const onSelectionFilters = (key: string, obj: any, verify: string): void => {

        dispatch(setAppLoaderAction(true));
        const modified: { key: string; title: string; verify: string; filter: string; } = { 
            key, filter: obj?.category === "" ? "" : (obj?.category || obj?.label || obj?.name || obj?.value || ''), verify,
            title: obj?.category || obj?.label || obj?.name || obj?.value || ''
        };

        if (obj?.category === "Other") {
            let str = '';
            obj?.data_by_list?.forEach((f: any) => {
                str = str + `&${key}=${f}`;
            });
            modified['filter'] = str;
        }

        if (filters.find(f => f.key === key)) {
            filters = filters.map(f => { return f.key === key ? modified : f });
        }
        else {
            filters = [ modified, ...filters ];
        }

        localStorage.setItem("report-filters", JSON.stringify(filters));

        getReportData(filters, false, isIgnoreZeroTivLocations, byLocationOrTiv);
        getBarChartData(filters, barChartMode, false, isIgnoreZeroTivLocations, byLocationOrTiv);
    };

    const onRemoveFilter = (key: string): void => {
        dispatch(setAppLoaderAction(true));
        filters = filters.filter(k => k.key !== key);

        localStorage.setItem("report-filters", JSON.stringify(filters));

        getReportData(filters, false, isIgnoreZeroTivLocations, byLocationOrTiv);
        getBarChartData(filters, barChartMode, false, isIgnoreZeroTivLocations, byLocationOrTiv);
    };

    const memoizedGeoCodeConfidence = React.useMemo(() => Object.entries(geoCodeConfidence)?.map(([key, value]: [string, any]) => {
        return {
            label: key,
            count: value?.count,
            tiv: value?.tiv,
            percentage: value?.percentage
        };
    }), [geoCodeConfidence]);

    const memoizedMapMarkers = React.useMemo(() => {
        return reportData?.mapData?.map((v: any) => { return { 
            ...v, count: 100,
            lat: Math.max(-90, Math.min(90, parseFloat(v.lat))).toFixed(4).toString(), 
            lng: (((parseFloat(v.lng) + 180) % 360 + 360) % 360 - 180).toFixed(4).toString()
        } })?.filter((f: any) => f?.lat !== "90.0000");
    }, [reportData?.mapData]);

    if (loading || !reportData) {
        return (
            <MasterLayout mainTitle={`${submission?.name_insured}`} navigate={() => navigate(`${Paths.dashboard}/${controlnumber}${search}`)} className='px-4 sm:px-6 lg:px-8' userEmail={user?.email as string}>
                <DocumentTitle title={`${submission?.name_insured}`}>
                    <div className="flex flex-col py-5 justify-center items-center">
                        { loading ? <SpinnerLoader adjustment={true} enhance="text-black text-base" /> : (
                            <span className="text-base text-black">Not found.</span>
                        )}
                    </div>
                </DocumentTitle>
            </MasterLayout>
        )
    }

    return (
        <MasterLayout mainTitle={`${submission?.name_insured  ? submission?.name_insured : ''}`} isReportTab={true} 
            className='px-2 relative' mainClassName="overflow-x-hidden" userEmail={user?.email as string}
            navigate={() => {
                filters = [];
                navigate(`${Paths.dashboard}/${controlnumber}${search}`);
                localStorage.removeItem("report-filters");
            }}
        >
            <DocumentTitle title={`${submission?.name_insured  ? submission?.name_insured : ''}`}>

                <div className="flex flex-row items-center justify-between px-3 mt-2">
                    { filters.length > 0 && (
                        <div className="flex flex-row items-center">
                            <span className="font-semibold mr-2 text-sm">Filters:</span>
                            { filters.map((f) => (
                                <div className='flex flex-row items-center mr-1 pt-[2px]' key={f.key}>
                                    <span className="capitalize text-sm mr-1"> {f.key.split('_').join(' ')} / </span>
                                    <TabWithRemove key={f.key} onClose={() => onRemoveFilter(f.key)} title={f.title} />
                                </div>
                            )) }
                            <button onClick={() => {
                                dispatch(setAppLoaderAction(true));
                                filters = [];
                                getReportData(filters, false, isIgnoreZeroTivLocations, byLocationOrTiv);
                                getBarChartData(filters, barChartMode, false, isIgnoreZeroTivLocations, byLocationOrTiv);
                                setTimeout(() => {
                                    getBarChartData(filters, barChartMode1, true, isIgnoreZeroTivLocations, byLocationOrTiv);
                                }, 1000);
                            }} className="inline-flex ml-3 items-center justify-center px-3 rounded-full bg-primary-blue-dark hover:bg-opacity-60 transition-all duration-200 h-6 text-xs font-semibold text-white shadow-sm focus-visible:outline">
                                Reset
                            </button>
                        </div>
                    )}
                    <div className="flex flex-row items-center ml-auto divide-x-2 divide-gray-200 gap-2">
                        <SwitchShortToggle enabled={byLocationOrTiv as boolean} setEnabled={(f: boolean) => {
                            dispatch(setAppLoaderAction(true));
                            setByLocationOrTiv(f);
                            getReportData(filters, false, isIgnoreZeroTivLocations, f);
                            getBarChartData(filters, barChartMode, false, isIgnoreZeroTivLocations, f);
                            getBarChartData(filters, barChartMode1, true, isIgnoreZeroTivLocations, f);
                        }} title='By TIV' isTwiceTitle="By Locations" />
                        <SwitchShortToggle enabled={isIgnoreZeroTivLocations as boolean} setEnabled={(f: boolean) => {
                            dispatch(setAppLoaderAction(true));
                            setIsIgnoreZeroTivLocations(f);
                            getReportData(filters, false, f, byLocationOrTiv);
                            getBarChartData(filters, barChartMode, false, f, byLocationOrTiv);
                            getBarChartData(filters, barChartMode1, true, f, byLocationOrTiv);
                        }} title='Ignore 0 TIV Locations' />
                    </div>
                </div>

                <div className="grid grid-cols-9 gap-2 h-[90vh] p-2">

                    <div className="col-span-4 relative bg-inherit border-2 border-gray-200 rounded-md p-2">
                        <Tabs 
                            activeClassName="mb-2 mr-3 button-39" inActiveClassName="mb-2 mr-3 button-39" onChange={(v) => {
                                setMapType(v);
                            }} active={mapType} 
                            tabs={[ { id: 1, name: 'Google Map' }, { id: 2, name: 'Heat Map' }, { id: 3, name: 'World Map' } ]} 
                        />

                        { mapType === 1 ? (
                            <GoogleMap mapHeight='h-[82vh]' markers={memoizedMapMarkers} />
                        ) : mapType === 2 ? (
                            <HeatMap markers={memoizedMapMarkers} />
                        ) : mapType === 3 ? (
                            <ChoroplethHeatMap 
                                countyMatrix={Methods.removeDublicatesInArray([])} styles={{ width: '100%', height: '82vh' }} 
                                id="clustered-map-4" markers={memoizedMapMarkers} 
                            />
                        ) : null }
                    </div>

                    <div className="col-span-5 relative bg-inherit h-[90vh]">

                        <div className='grid grid-cols-4 gap-2 h-[16vh]'>
                            <TIVChartSection 
                                tiv={reportData?.coverages?.tiv ?? 0} coverages={reportData?.coverages?.coverages} 
                            />
                            <GuageChartSection report={{ 
                                dataMapped: reportData?.dataMapped, dataCompleteness: reportData?.dataCompleteness,
                                dataQuality: reportData?.dataQuality
                            }} />
                        </div>

                        <div className='relative col-span-3 border-2 border-gray-200 rounded-md pt-2 pl-2 my-2 h-[46vh]'>
                            { dataCoverageByGeography?.length > 0 && (
                                <BarChartSection
                                    barChartMode={barChartMode} locationCount={reportData?.coverages?.location_count ?? 0} report={dataCoverageByGeography}
                                    onSelection={(obj) => onSelectionFilters(
                                        Object.entries(Constants.getDataByOptions).find(([_, val]) => val === barChartMode)?.[0] as string, obj, 'bar'
                                    )} 
                                    isModifiedData={false} barChartMode1={barChartMode1} totalLocations1={reportData?.coverages?.location_count ?? 0}
                                    setBarChartMode1={(str) => {
                                        barChartMode1 = str;
                                        getBarChartData(filters, str, true, isIgnoreZeroTivLocations, byLocationOrTiv);
                                    }} 
                                    report1={dataCoverageByGeography1} byLocationOrTiv={byLocationOrTiv}
                                    setBarChartMode={(str) => {
                                        if (str !== barChartMode && filters.filter(f => f.verify === 'bar').length > 0) {
                                            // filters = filters.filter(f => f.verify !== 'bar');
                                            barChartMode = str;
                                            
                                            setTimeout(() => {
                                                getReportData(filters, false, isIgnoreZeroTivLocations, byLocationOrTiv);
                                                getBarChartData(filters, str, false, isIgnoreZeroTivLocations, byLocationOrTiv);
                                            }, 500);
                                        }
                                        else {
                                            barChartMode = str;
                                            getBarChartData(filters, str, false, isIgnoreZeroTivLocations, byLocationOrTiv);
                                        }
                                    }}
                                />
                            )}
                        </div>

                        <div className='grid grid-cols-3 gap-2 h-[26vh]'>
                            <div className='relative col-span-1 border-2 border-gray-200 rounded-md p-2'>
                                <GeoCodeConfidenceSection 
                                    data={memoizedGeoCodeConfidence} byLocationOrTiv={byLocationOrTiv}
                                    onSelection={(obj) => onSelectionFilters("geocode_confidence", obj, 'geo')}
                                    filterKeyValue={filters?.find(f => f.key === 'geocode_confidence' && f.verify !== 'bar')?.filter ?? ''}
                                />
                            </div>
                            <div className='relative col-span-1 border-2 border-gray-200 rounded-md p-2'>
                                <MappingDoughnutSection 
                                    onSelection={(k) => onSelectionFilters('construction_code', k, 'const')} chartId={`mappings-modal-${1}`}
                                    activeTab={1} item={{ title: 'Construction', value: (constructions?.pie_chart_data ?? [])?.length ?? 0 }} 
                                    data={constructions?.pie_chart_data ?? []} 
                                    tableData={constructions?.mapping_data?.map((v: any) => {
                                        return { construction: v?.raw_construction, scheme: v?.scheme, construction_code_id: v?.code, mapping: v?.description }
                                    })}
                                    filterKeyValue={filters?.find(f => f.key === 'construction_code' && f.verify !== 'bar')?.title ?? ''}
                                />
                            </div>
                            <div className='relative col-span-1 border-2 border-gray-200 rounded-md p-2'>
                                <MappingDoughnutSection 
                                    onSelection={(k) => onSelectionFilters('occupancy_code', k, 'occ')} chartId={`mappings-modal-${2}`}
                                    activeTab={2} item={{ title: 'Occupancy', value: (occupancies?.pie_chart_data ?? [])?.length ?? 0 }} 
                                    data={occupancies?.pie_chart_data ?? []} 
                                    tableData={occupancies?.mapping_data?.map((v: any) => {
                                        return { occupancy: v?.raw_occupancy, scheme: v?.scheme, occupancy_code_id: v?.code, mapping: v?.description }
                                    })}
                                    filterKeyValue={filters?.find(f => f.key === 'occupancy_code' && f.verify !== 'bar')?.title ?? ''}
                                />
                            </div>
                        </div>

                    </div>

                </div>

            </DocumentTitle>
        </MasterLayout>
    );
}

const MemoizedReportAnalyticsBE = React.memo(ReportAnalyticsBE);

export default withAuthentication(MemoizedReportAnalyticsBE, Paths.login, 1);
import { useState, useEffect, useMemo } from 'react';

import { ICompanyInvoice, ISubmissionListItem } from 'Libraries/Interfaces';
import ApiRequest from 'Services/ApiRequest';

export const useGenerateCompanyInvoice = (params: string): {
    companyInvoice: ICompanyInvoice | null, resetHookState: () => void, loading: boolean
} => {

    const [loading, setLoading] = useState<boolean>(false);
    const [companyInvoice, setCompanyInvoice] = useState<ICompanyInvoice | null>(null);

    useEffect(() => {
        if (params) {
            getInitials(params);
        }
    }, [params]);

    async function getInitials(params: string) {
        setLoading(true);
        try {
            const res = await ApiRequest.generateInvoice(params);
            setCompanyInvoice(res?.data as ICompanyInvoice);
            setLoading(false);            
        }
        catch (err) {
            setCompanyInvoice(null);
            setLoading(false);
        }
    }

    return useMemo(() => {
        return {
            companyInvoice,
            resetHookState: () => setCompanyInvoice(null),
            loading
        }
    }, [companyInvoice, loading]);
}

export const useFetchCurrentSubmission = (controlNumber: string): { loading: boolean; submission: ISubmissionListItem | undefined; } => {

    const [loading, setLoading] = useState<boolean>(false);
    const [submission, setSubmission] = useState<ISubmissionListItem | undefined>(undefined);

    useEffect(() => {
        if (controlNumber) {
            getInitials(controlNumber);
        }
    }, [controlNumber]);

    async function getInitials(controlNumber: string) {
        setLoading(true);
        const res = await ApiRequest.getASubmissionRequest(controlNumber);
        setSubmission(res?.data);
        setLoading(false);
    }

    return useMemo(() => {
        return { submission, loading };
    }, [submission, loading]);
}
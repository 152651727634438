import * as React from 'react';
import { Images } from 'Libraries/Images';

import Dropdown from 'Components/Dropdown/Dropdown';
import Constants from 'Libraries/Constants';
import Methods from 'Libraries/CommonMethodsUI';
import { IReportData } from 'Libraries/Interfaces';
import { AMGeographyBarChart, useFetchBarChartDataSets } from 'Components/Charts/BarChart';
import Modal from 'Components/Modal/Modal';
import { CustomTable3 } from 'Components/CustomTable/CustomTable';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { HiOutlineInformationCircle } from "react-icons/hi2";
import { ForceDirectedLinksMap } from 'Components/Charts/PieChart';
import { AMPieDoughnutChart } from 'Components/Charts/ChartsCommon';
import OutsideClickHandler from 'react-outside-click-handler';
import { GuageChart } from 'Components/Charts/ClusteredChart';
import { Paths } from 'Libraries/Route';
import { DataCompletenessForReport, DataMappedForReport, DataQualityForReport } from './DataReports';
import TivCardContent from 'Components/Charts/TIVCard';
import { AiOutlineExpand } from 'react-icons/ai';
import { SwitchShortToggle } from 'Components/SwitchToggle/SwitchToggle';

export function MainTitle({ title, fontSize, textDecoration }: { title: string, fontSize: number, textDecoration: any }): JSX.Element {
    return(
        <h6 className='font-bold text-center mb-3 text-black' style={{ fontSize: fontSize, textDecoration: textDecoration }}>
            { title }
        </h6>
    );
}

export function ReportHeaderMin(): JSX.Element {
    return(
        <div className="w-full h-[50px]">
            <img alt="header-line" src={Images.reportLine} className="w-full h-[7px]" />
        </div>
    );
}

export function FirstPageHeading({ insuredName }: { insuredName: string }): JSX.Element {
    return (
        <div className="justify-center flex flex-col items-center h-[74vh]">
            <h6 className="m-0 text-center font-bold text-[40px] w-[600px] min-w-[600px] text-[#0991cd] break-words">
                { insuredName }
            </h6>
            <br />
            <h6 className="text-[32px] m-0 font-bold text-black text-center w-[450px] min-w-[450px]">
                Exposure Detail Report
            </h6>
            <span className="text-[13px] mt-3 font-semibold text-[#949494] text-center w-[200px] min-w-[200px]">Powered by SOV Wizard</span>
        </div>
    );
}

export function ReportFooter(): JSX.Element {
    return(
        <div className="h-24 border-t-2 border-yellow-400 w-full">
            <div className="flex flex-row items-center justify-center pt-1 relative">
                <div className="flex-1 pl-2">
                    <div className="flex flex-row items-center">
                        <div className="text-xs font-semibold mr-1 hidden">
                            Wizard Analytics
                        </div>
                        <div className="text-xs font-semibold">
                            
                        </div>
                    </div>
                    <div className="flex flex-row items-center">
                        <div className="text-xs font-semibold mr-1 hidden">
                            Website:
                        </div>
                        <div className="text-xs font-bold">
                            https://sovwizard.com/
                        </div>
                    </div>
                    <div className="flex flex-row items-center">
                        <div className="text-xs font-semibold mr-1 hidden">
                            Email Address:
                        </div>
                        <div className="text-xs font-bold">
                            contact@wizardanalytics.io
                        </div>
                    </div>
                </div>
                <div className="pr-1.5">
                    <img alt="logo" src={Images.completeLogo} className="w-40" />
                </div>
            </div>
            <div>
                <img alt="report line" src={Images.reportLine} className="w-full h-[6px]" />
            </div>
            <div>
                <span className="pl-2 text-xs block transform text-gray-500 opacity-75">
                    Copyright &copy; {new Date().getFullYear()} Wizard Analytics All rights reserved.
                </span>
            </div>
        </div>        
    );
}

export const GeoCodeConfidenceSection: React.FC<{
  data: { label: string; count: number; percentage: number; tiv?: number; }[]; onSelection?: (obj: any) => void; filterKeyValue?: string;
  byLocationOrTiv: boolean;
}> = React.memo(({ data, onSelection, filterKeyValue, byLocationOrTiv }) => {
  
  const [isModal, setIsModal] = React.useState<boolean>(false);
  const [random, setRandom] = React.useState<number>(0);

  const [isOpen1, setIsOpen1] = React.useState<boolean>(false);
  const [isOpen2, setIsOpen2] = React.useState<boolean>(false);

  const totalLocations: number = 10;

  React.useEffect(() => {
    if (isModal) {
      setTimeout(() => {
        setRandom(Math.random());
      }, 500);
    }
  }, [isModal]);

  const TooltipContent = ({ id, width, isOpen, setIsOpen }: { id: string; width: number; isOpen: boolean, setIsOpen: (f: boolean) => void }) => React.useMemo(() => {
    return (
      <ReactTooltip 
          arrowColor='#2D2D2D' id={id} opacity={1} closeOnEsc isOpen={isOpen} setIsOpen={setIsOpen} openOnClick 
          className='overflow-y-auto z-50 overflow-x-hidden border-2 border-black'
          place="right-start" noArrow={false} clickable={true} variant="info" style={{ backgroundColor: '#f1f1f1', height: 250, width: width }} 
      >
        <CustomTable3 mainClass='h-auto' isBackFilter='backdrop-blur backdrop-filter' headers={[
            { value: "Geocode Confidence", textAlign: "left", width: "33.33%" },
            { value: byLocationOrTiv ? "TIV" : "Count", textAlign: "left", width: "33.33%" },
            { value: "Mapped Percentage", textAlign: "left", width: "33.33%" }
        ]}>
            {data?.map((s, i: number) => {
                return (
                  <tr key={i} className="border-collapse outline-0 align-middle table-row text-inherit">
                      { [ s?.label, byLocationOrTiv ? Methods.numberFormatter(s?.tiv as number) : s?.count, `${s?.percentage}%` ].map((h: string | any, i: number) => (
                          <td className='leading-6 text-sm table-cell league-spartan-font text-[#6C757D] border-b border-[#EAEAEA] px-4 w-1/3 py-1.5 text-left' key={i}> {h} </td>
                      )) }
                  </tr>
                );
            })}
        </CustomTable3>
      </ReactTooltip>
    );
  }, [id, width, isOpen, setIsOpen]);

  return (
    <React.Fragment>
      <HiOutlineInformationCircle onClickCapture={() => setIsOpen1(!isOpen1)} size={18} className='cursor-pointer absolute right-2 z-20 top-2' data-tooltip-id="geocode-confidence" onClick={(e) => e.stopPropagation()} />
      <TooltipContent id="geocode-confidence" width={500} isOpen={isOpen1} setIsOpen={setIsOpen1} />
      <AiOutlineExpand onClick={() => {
        setIsModal(true);
        setIsOpen1(false);
      }} className="absolute cursor-pointer right-8 z-20 top-2" size={18} />

      <ForceDirectedLinksMap 
        totalLocations={totalLocations} onSelection={onSelection} filterKeyValue={filterKeyValue}
        id="geocode-confidence" arr={data} styles={{ width: '100%', height: '23vh' }} 
        byLocationOrTiv={byLocationOrTiv}
      />
      
      <Modal open={isModal} setOpen={() => setIsModal(false)}>
        <OutsideClickHandler onOutsideClick={() => setIsOpen2(false)}>
          <HiOutlineInformationCircle onClickCapture={() => setIsOpen2(!isOpen2)} size={18} className='cursor-pointer absolute right-2 z-20 top-2' data-tooltip-id="geocode-confidence-modal" onClick={(e) => e.stopPropagation()} />
          <TooltipContent id="geocode-confidence-modal" width={600} isOpen={isOpen2} setIsOpen={setIsOpen2} />
        </OutsideClickHandler>

        <ForceDirectedLinksMap 
          totalLocations={totalLocations} isUpdation={random} isLarge onSelection={onSelection}
          id="geocode-confidence-modal" arr={data} styles={{ width: '100%', height: '60vh' }} 
          filterKeyValue={filterKeyValue} byLocationOrTiv={byLocationOrTiv}
        />
      </Modal>
    </React.Fragment>
  );
})

export const BarChartSection: React.FC<{
  report: any; isModifiedData: boolean; barChartMode: string; setBarChartMode: (str: string) => void; 
  onSelection?: (obj: any) => void; locationCount?: number; byLocationOrTiv?: boolean;
  barChartMode1: string; setBarChartMode1: (str: string) => void; 
  totalLocations1?: number; report1?: any;
}> = ({ 
  report, isModifiedData, barChartMode, setBarChartMode, onSelection, locationCount, barChartMode1, setBarChartMode1,
  report1, totalLocations1, byLocationOrTiv
}) => {
  
  const [isModal, setIsModal] = React.useState<boolean>(false);
  const [isCompare, setIsCompare] = React.useState<boolean>(false);
  const [random, setRandom] = React.useState<number>(0);

  const totalLocations: number = !report ? 0 : locationCount ? locationCount : report?.summaryTable?.[2]?.value as any;
  const { data } = useFetchBarChartDataSets(isModifiedData ? report?.utilityMatrices : {});
  
  const getInitialBarChartMode = React.useCallback(() => {
    if (isModifiedData) {
      let str: string = '';
      const { stateMatrix, countriesMatrix, postalMatrix, countyMatrix } = report?.utilityMatrices;
  
      const countries: string[] = Methods.removeDublicatesInArray(countriesMatrix?.map((v: any) => v.label) ?? []);
      const states: string[] = Methods.removeDublicatesInArray(stateMatrix?.map((v: any) => v.label) ?? []);
      const postals: string[] = Methods.removeDublicatesInArray(postalMatrix?.map((v: any) => v.label) ?? []);
      const counties: string[] = Methods.removeDublicatesInArray(countyMatrix?.map((v: any) => v.label) ?? []);
  
      if (countries?.length > 1) {
        str = "Country";
      }
      else if (states?.length > 1) {
        str = 'State';
      }
      else if (states?.length === 1 && counties?.length > 1) {
        str = 'County';
      }
      else if (counties?.length === 1 && postals?.length > 1) {
        str = 'Postal';
      }
      else if (postals?.length > 0) {
        str = postals?.length === 1 ? 'City' : 'Postal';
      }
      else {
        str = "City";
      }
      setBarChartMode(str);
    }
  }, [isModifiedData]);

  React.useEffect(() => {
    getInitialBarChartMode();
  }, [getInitialBarChartMode]);

  React.useEffect(() => {
    if (isModal) {
      setTimeout(() => {
        setRandom(Math.random());
      }, 500);
    }
  }, [isModal]);

  return (
    <React.Fragment>
      <div className="flex flex-row items-center justify-between mb-2">
        <span className="font-semibold text-lg text-black flex-1 text-center">{totalLocations?.toLocaleString("en-US")} {totalLocations > 1 ? 'Locations' : 'Location'}</span>
        <div className='flex flex-row items-center'>
          <AiOutlineExpand onClick={() => setIsModal(true)} className="cursor-pointer mr-2" size={18} />
          <Dropdown
            arr={Object.values(isModifiedData ? Constants.barChartModeTypes : Constants.getDataByOptions).flat(isModifiedData ? 1 : 0).map((v, i) => { return { id: i+11, name: v } })}
            placeholder={barChartMode ? barChartMode : "Selection"} styles={`pr-0 text-[15px] mr-2`} isSmallIcon 
            setData={(e: any) => {
              setBarChartMode(e?.name);
            }}
          />
        </div>
      </div>
    
      <AMGeographyBarChart 
        isModifiedData={isModifiedData} id="barchart-1" className='h-[38vh]' data={isModifiedData ? data : report} reportTitle={false}
        mode={isModifiedData ? Methods.getKeyByValue(Constants.barChartModeTypes, barChartMode) : ""}
        onSelection={onSelection} byLocationOrTiv={byLocationOrTiv}
      />
      
      <Modal open={isModal} setOpen={() => setIsModal(false)} modalWidthClass='sm:max-w-7xl'>
        <div className={`grid grid-cols-${isCompare ? '2' : '1'} divide-x`}>
          <div className='col-span-1'>
            <div className="flex items-center justify-between mb-2">
              <div className='flex flex-row items-center justify-center flex-1'>
                <span className="font-semibold text-lg text-black text-center">{totalLocations} {totalLocations > 1 ? 'Locations' : 'Location'}</span>
                <button className="button-39 py-1 text-xs px-2 ml-2" onClick={() => setIsCompare(!isCompare)}> {!isCompare ? 'Compare' : 'Cancel'} </button>
              </div>
              <Dropdown
                arr={Object.values(isModifiedData ? Constants.barChartModeTypes : Constants.getDataByOptions).flat(isModifiedData ? 1 : 0).map((v, i) => { return { id: i+11, name: v } })} 
                placeholder={barChartMode ? barChartMode : "Selection"} 
                styles={`pr-0 text-[15px]`} isSmallIcon 
                setData={(e: any) => {
                  setBarChartMode(e?.name);
                }}
              />
            </div>
            <AMGeographyBarChart 
              isModifiedData={isModifiedData} isUpdation={random} id={`barchart-modal-0`} className="h-[60vh]" reportTitle={false}
              mode={isModifiedData ? Methods.getKeyByValue(Constants.barChartModeTypes, barChartMode) : ""} data={isModifiedData ? data : report}
              isCompare={isCompare} byLocationOrTiv={byLocationOrTiv}
            />
          </div>
          { isCompare && (
            <div className='col-span-1'>
              <div className="flex items-center justify-between mb-2">
                <div className='flex flex-row items-center justify-center flex-1'>
                  <span className="font-semibold text-lg text-black text-center">{totalLocations1} {(totalLocations1 as number) > 1 ? 'Locations' : 'Location'}</span>
                  <button className="button-39 py-1 text-xs px-2 ml-2" onClick={() => setIsCompare(!isCompare)}> {!isCompare ? 'Compare' : 'Cancel'} </button>
                </div>
                <Dropdown
                  arr={Object.values(isModifiedData ? Constants.barChartModeTypes : Constants.getDataByOptions).flat(isModifiedData ? 1 : 0).map((v, i) => { return { id: i+11, name: v } })} 
                  placeholder={barChartMode1 ? barChartMode1 : "Selection"} 
                  styles={`pr-0 text-[15px]`} isSmallIcon 
                  setData={(e: any) => {
                    setBarChartMode1(e?.name);
                  }}
                />
              </div>
              <AMGeographyBarChart 
                isModifiedData={isModifiedData} isUpdation={random} id={`barchart-modal-1`} className="h-[60vh]" reportTitle={false}
                mode={isModifiedData ? Methods.getKeyByValue(Constants.barChartModeTypes, barChartMode1) : ""} data={isModifiedData ? data : report1}
                isCompare={isCompare} byLocationOrTiv={byLocationOrTiv}
              />
            </div>
          )}
        </div>
      </Modal>
    </React.Fragment>
  );
}

export const MappingDoughnutSection: React.FC<{
  data: any[]; chartId: string; activeTab: number; item: { value: number; title: string; }; 
  tableData: any[]; onSelection?: (obj: any) => void; filterKeyValue?: string;
}> = ({ data, chartId, activeTab, item, tableData, onSelection, filterKeyValue }) => {

  const [sortingHeader, setSortingHeader] = React.useState<{ flag: number; value: string; direction: string; byColumn: string; }>({ value: '', flag: 0, direction: "", byColumn: "" });
  
  const [isModal, setIsModal] = React.useState<boolean>(false);
  const [random, setRandom] = React.useState<number>(0);

  const [isOpen1, setIsOpen1] = React.useState<boolean>(false);
  const [isOpen2, setIsOpen2] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (isModal) {
      setTimeout(() => {
        setRandom(Math.random());
      }, 500);
    }
  }, [isModal]);

  const onSorting = React.useCallback((v: any) => {
    const column = v.selected === 'Mapped to Code' ? 'mapping' : v.selected === 'Mapped to Scheme' ? 'scheme' : activeTab === 1 ? 'construction' : 'occupancy';
    setSortingHeader({ byColumn: column, direction: v.flag === 1 ? 'asc' : 'desc', flag: v.flag, value: v.selected });
  }, [activeTab, setSortingHeader]);

  const MemoizedCustomTable3 = React.memo(CustomTable3);

  const TooltipContent = React.memo(({ id, width, isOpen, isModal }: { isModal: boolean; id: string; width: number; isOpen: boolean, setIsOpen: (f: boolean) => void }) => {
    if (!isOpen) return null;

    return (
      <div
        className={`overflow-y-auto z-50 overflow-x-hidden p-3 rounded-md border-2 border-black absolute ${isModal ? 'right-6 top-5' : 'right-7 bottom-10'}`}
        style={{ height: 255, width: width, backgroundColor: '#f1f1f1' }} id={id}
      >
        <div className="flex flex-row items-center justify-between mb-3">
          <span className="font-semibold text-xs text-black">{activeTab === 1 ? 'Construction' : 'Occupancy'} Mapping Assumptions</span>
        </div>

        <MemoizedCustomTable3 
          mainClass='max-h-[25vh]' isBackFilter='backdrop-blur backdrop-filter' sortingHeader={sortingHeader} headers={[{
            value: `Reported ${activeTab === 1 ? 'Construction' : 'Occupancy'}`, textAlign: "left", width: "33.33%" },
            { value: "Mapped to Scheme", textAlign: "left", width: "33.33%" },
            { value: "Mapped to Code", textAlign: "left", width: "33.33%" }
          ]}
          onSorting={onSorting}
        >
          {tableData?.sort((a, b) => {
            if (sortingHeader.direction === 'asc') {
              return a[sortingHeader.byColumn] < b[sortingHeader.byColumn] ? -1 : a[sortingHeader.byColumn] > b[sortingHeader.byColumn] ? 1 : 0;
            } else {
              return a[sortingHeader.byColumn] > b[sortingHeader.byColumn] ? -1 : a[sortingHeader.byColumn] < b[sortingHeader.byColumn] ? 1 : 0;
            }
          })?.map((c: any) => (
            <tr key={activeTab === 1 ? c?.construction : c?.occupancy} className="border-collapse outline-0 align-middle table-row text-inherit">
              { [activeTab === 1 ? c?.construction : c?.occupancy, c?.scheme, c?.mapping ? `${c?.mapping} (${activeTab === 1 ? c?.construction_code_id : c?.occupancy_code_id})` : ""].map((h: string, i: number) => (
                  <td className='leading-6 text-sm table-cell league-spartan-font text-[#6C757D] border-b border-[#EAEAEA] px-4 w-1/3 py-1.5 text-left' key={i}> {h} </td>
              )) }
            </tr>
          ))}
        </MemoizedCustomTable3>
      </div>
    );
  });

  return (
    <div className="flex flex-col items-center justify-center cursor-pointer">
      <HiOutlineInformationCircle onClickCapture={() => setIsOpen1(!isOpen1)} size={18} className='cursor-pointer absolute right-2 z-20 top-2' data-tooltip-id={`mappings-${activeTab}`} onClick={(e) => e.stopPropagation()} />
      <OutsideClickHandler onOutsideClick={() => setIsOpen1(false)}>
        <TooltipContent isModal={false} id={`mappings-${activeTab}`} width={670} isOpen={isOpen1} setIsOpen={setIsOpen1} />
      </OutsideClickHandler>
      <AiOutlineExpand onClick={() => {
        setIsModal(true);
        setIsOpen1(false);
      }} className="absolute cursor-pointer right-8 z-20 top-2" size={18} />

      <AMPieDoughnutChart 
        styles={{ width: "200px", height: "20vh" }} id={`${activeTab === 1 ? 'construction' : 'occupancy'}-mappings-1`}
        data={data} isLegendVisible={false} onSelection={onSelection} filterKeyValue={filterKeyValue}
      />

      <div className='flex flex-row items-center justify-center'>
        <h6 className="font-semibold text-lg text-black"> {item.title} </h6>
      </div>

      <Modal open={isModal} setOpen={() => setIsModal(false)}>
        <OutsideClickHandler onOutsideClick={() => setIsOpen2(false)}>
          <HiOutlineInformationCircle onClickCapture={() => setIsOpen2(!isOpen2)} size={18} className='cursor-pointer absolute right-2 z-20 top-2' data-tooltip-id={'mappings-modal'} onClick={(e) => e.stopPropagation()} />
          <TooltipContent isModal={true} id={'mappings-modal'} width={770} isOpen={isOpen2} setIsOpen={setIsOpen2} />
        </OutsideClickHandler>

        <AMPieDoughnutChart 
          styles={{ width: "100%", height: "60vh" }} id={chartId}
          data={data} isLegendVisible={false} isUpdation={random}
          isTicksEnable={true} onSelection={onSelection} filterKeyValue={filterKeyValue}
        />
      </Modal>
    </div>
  );
}

export const GuageChartSection = ({ report }: { report: any }) => {

  const [isModal, setIsModal] = React.useState<{ flag: boolean; id: number; }>({ flag: false, id: 0 });
  const [random, setRandom] = React.useState<number>(0);

  return (
    <>
      {[
        { id: 1, chartTitle: 'Mapped', label: "DM", place: "top", percentage: !report?.dataMapped ? 0 : report?.dataMapped?.data_mapped_score/100, route: Paths.dataMapped, nrOfLevels: 420, colors: ["#65CC56", "#E9C621", "#AA1F00"], arcsLength: !report?.dataMapped ? [] : report?.dataMapped?.data_for_chart?.percentages?.map((v: number) => v/100) },
        { id: 2, chartTitle: 'Complete', label: "DC", place: "top-end", percentage: report?.dataCompleteness?.data_completeness_score/100, route: Paths.dataCompleteness, nrOfLevels: 420, colors: ["#65CC56", "#E9C621", "#AA1F00"], arcsLength: report?.dataCompleteness?.data_for_chart?.percentages?.map((v: number) => v/100) },
        { id: 3, chartTitle: 'Quality', label: "DQ", place: "top-end", percentage: report?.dataQuality?.data_quality_score/100, route: Paths.dataQuality, nrOfLevels: 420, colors: ["#65CC56", "#E9C621", "#AA1F00"], arcsLength: report?.dataQuality?.data_for_chart?.percentages?.map((v: number) => v/100) }
        ].map((item) => (
        <div data-tooltip-id={item.chartTitle} onClick={() => {
          setIsModal({ flag: true, id: item.id });
          setTimeout(() => {
            setRandom(Math.random());
          }, 500);
        }} key={item.label} className="bg-inherit overflow-hidden border-2 border-gray-200 rounded-md col-span-1 flex flex-col items-center justify-center cursor-pointer">
          <GuageChart title={item.chartTitle} id={`g-map${item.id}`} styles={{ width: '100%', height: '12vh' }} percentage={item.percentage*100}  />
          <div className="flex flex-row items-center justify-center overflow-hidden font-semibold text-base text-black">
            <span> { item.percentage === 0 ? '0%' : item.percentage ? `${Math.round(item.percentage*100)}%` : 'N/A'} </span>
          </div>

          <ReactTooltip place="left" id={item.chartTitle} opacity={1} closeOnEsc content={`${ item.percentage ? `${Math.round(item.percentage*100)}%` : 'N/A'} of Data ${item.chartTitle}`} />
        </div> 
      ))}

      <Modal open={isModal.flag} setOpen={() => setIsModal({ flag: false, id: isModal.id })}>
        { isModal.id === 1 ? (
            <DataMappedForReport isUpdation={random} dataMapped={report?.dataMapped} title="Data Mapped Report" />
        ) : isModal.id === 2 ? (
            <DataCompletenessForReport isUpdation={random} dataCompleteness={report?.dataCompleteness} title="Data Completeness Report" />
        ) : isModal.id === 3 ? (
            <DataQualityForReport isUpdation={random} title="Data Quality Report" dataQuality={report?.dataQuality} />
        ) : null }
      </Modal>
    </>
  );
}

export const TIVChartSection = ({ tiv, coverages }: { 
  tiv: number, coverages: { [key: string]: { value: number; percentage: number; }; }
}) => {

  const [isModal, setIsModal] = React.useState<boolean>(false);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [random, setRandom] = React.useState<number>(0);

  const isOpenModal = (e: any): void => {
    e.stopPropagation(); 
    setIsOpen(false);
    setIsModal(true);
    setTimeout(() => {
      setRandom(Math.random());
    }, 500);
  }

  return (
    <div   
      className={`cursor-pointer relative bg-inherit border-2 border-gray-200 rounded-md col-span-1 flex flex-col items-center justify-center`}
    >
      <div       
        onClick={(e) => isOpenModal(e)}
        className="text-center flex flex-col justify-between items-center"
      >
        <span className="font-semibold text-4xl text-black">${Methods.numberFormatter(tiv)}</span>
      </div>

      <HiOutlineInformationCircle 
          size={18} className='cursor-pointer absolute right-2 z-20 top-4' 
          data-tooltip-id={"tiv-chart-table"} onClick={(e) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }} 
      />

      <h6 onClick={(e) => isOpenModal(e)} className="text-gray-400 text-lg absolute bottom-1"> TIV </h6>

      <ReactTooltip id="tiv-chart-table" opacity={1} arrowColor='#2D2D2D' closeOnEsc isOpen={isOpen} setIsOpen={setIsOpen} openOnClick
        place="bottom-start" noArrow={true} className='overflow-y-auto z-50 overflow-x-hidden border-2 border-black'
        style={{ backgroundColor: '#f1f1f1', height: 260, width: 620 }}
      >
          <CustomTable3 mainClass='h-auto' headers={[
              { value: "Coverage", textAlign: "left", width: "33.33%" },
              { value: "Value", textAlign: "left", width: "33.33%" },
              { value: "Percentage", textAlign: "left", width: "33.33%" }
          ]}>
              {[
                  { label: "Building Limit", value: Math.round(coverages?.building?.value), percentage: Math.round(coverages?.building?.percentage) },
                  { label: "Other Limit", value: Math.round(coverages?.other?.value), percentage: Math.round(coverages?.other?.percentage) },
                  { label: "Contents Limit", value: Math.round(coverages?.contents?.value), percentage: Math.round(coverages?.contents?.percentage) },
                  { label: "BI Limit", value: Math.round(coverages?.bi?.value), percentage: Math.round(coverages?.bi?.percentage) },
                  { label: "TIV", value: Math.round(tiv), percentage: 100 }
              ].map((s, ind: number) => {
                  return (
                      <tr key={ind} className="border-collapse outline-0 align-middle table-row text-inherit">
                          { [
                              s?.label, '$'+s?.value?.toLocaleString("en-US"), s?.percentage + '%'
                          ].map((h: string | any, i: number) => (
                              <td 
                                  className={`
                                      leading-6 text-sm table-cell league-spartan-font text-[#6C757D] px-4 w-1/3 py-1.5 text-left
                                      border-[#EAEAEA] ${ind === 3 ? 'border-b-2' : 'border-b'} ${(ind === 4) ? 'font-bold' : 'font-normal'}`}
                                  key={i}> {h} 
                              </td>
                          )) }
                      </tr>
                  );
              })}
          </CustomTable3>
      </ReactTooltip>

      <Modal open={isModal} setOpen={() => setIsModal(false)}>
        <TivCardContent isUpdation={random} coverages={coverages} totalTiv={tiv} />
      </Modal>
    </div>
  );
}

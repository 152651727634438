import * as React from 'react';

import { SpinnerLoader } from 'Components/Loader/Loader';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";

interface IProps {
    headers: { data: string[]; hasIcons: boolean; modified?: any; };
    children: JSX.Element[] | any;
    emptyList: boolean;
    loading: boolean;
    maxHeight: string;
    minWidth?: number;
}

export const CustomTable: React.FC<IProps> = ({ headers, children, emptyList, loading, maxHeight, minWidth }) => {
    return (
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 remove-scrollbar">
            <div className={`inline-block min-w-full overflow-x-auto pb-2 align-middle sm:px-6 lg:px-8 ${maxHeight}`}>
                <table className="min-w-full text-left table-auto">
                    <thead className='sticky top-0 z-0 backdrop-blur backdrop-filter bg-white bg-opacity-75'>
                        { headers.modified ? headers.modified : (
                            <tr className='border-b-2 border-primary-grey'>
                                { [...headers.data].map((head: string, index) => (
                                    <th key={index} scope="col" style={{ minWidth: minWidth ? (minWidth + 'px') : 'auto' }} className="py-4 relative px-3 text-left text-sm font-bold text-[#343A40]">
                                        {head}
                                        { index > 2 && (
                                            <span className={headers.hasIcons ? 'absolute right-6 top-1' : 'hidden'}>
                                                <MdArrowDropUp className='cursor-pointer text-2xl text-[#155D9C] m-0' />
                                                <MdArrowDropDown className='cursor-pointer text-2xl text-primary-grey-10 m-0 -mt-2' />
                                            </span>
                                        )}
                                    </th>
                                )) }
                            </tr>
                        ) }
                    </thead>
                    <tbody className={`remove-scrollbar overflow-y-auto ${maxHeight}`}>
                        {loading ? (
                            <tr className='transition-all duration-200 cursor-pointer'>
                                <td className="h-10" colSpan={headers.modified ? 8 : headers.data.length}>
                                    <SpinnerLoader adjustment={true} enhance="text-black text-base" />
                                </td>
                            </tr>
                        ) : null }
                        { (!loading && emptyList) ? (
                            <tr className='transition-all duration-200 cursor-pointer'>
                                <td className="items-center h-20" colSpan={headers.data.length}>
                                    <span className='text-base text-black text-center inline-flex justify-center w-full'>No data available.</span>
                                </td>
                            </tr>
                        ) : children }
                    </tbody>
                    <tfoot>
                        <tr><td className="items-center" colSpan={headers.data.length}>
                            <br /><br /><br /><br /><br /><br /><br />
                        </td></tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
}

interface ICustomTable2 {
    headers: { width: string; textAlign: any; value: string; }[]; 
    children: JSX.Element[] | JSX.Element | any; 
    tableContainerStyle?: any;
    mainClass?: string;
    isBackFilter?: string;
    onSorting?: (obj: { flag: number; selected: string; }) => void;
    sortingHeader?: any;
}

export default function CustomTable2({ headers, children, tableContainerStyle, mainClass }: ICustomTable2): JSX.Element {
    return (
        <div
            className={`bg-white remove-scrollbar overflow-y-auto rounded ${mainClass}`}
            style={{ ...tableContainerStyle, transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms', boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)' }}
        >
            <table className="min-w-full w-full border-collapse border-spacing-0 table">
                <thead className="bg-[#f1f1f1] table-header-group border-collapse border-spacing-0">
                    <tr className="border-collapse outline-0 align-middle table-row text-inherit">
                        { [...headers].map((item, i: number) => (
                            <th scope='col' className='sticky top-0 backdrop-blur backdrop-filter leading-6 text-xs table-cell text-black border-b border-[#f1f1f1] px-4 py-1.5' style={{ width: item.width, verticalAlign: 'inherit', textAlign: item.textAlign }} key={i}>
                                {item.value}
                            </th>
                        )) }
                    </tr>
                </thead>
                <tbody className="table-row-group">
                    { children }
                </tbody>
            </table>
        </div>
    );
}

export function CustomTable3({ headers, children, tableContainerStyle, mainClass, isBackFilter, onSorting, sortingHeader }: ICustomTable2): JSX.Element {

    const onClick = (e: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>, selected: string) => {
        e.stopPropagation();
        
        if (sortingHeader.value === selected) {
            // Toggle the sorting flag for the same column
            const newFlag = sortingHeader.flag === 1 ? 2 : 1;
            if (onSorting)
                onSorting({ flag: newFlag, selected });        
        } else {
            // Start sorting from ascending when switching to a new column
            if (onSorting)
                onSorting({ flag: 1, selected });
        }
    };

    return (
        <div
            className={`bg-white remove-scrollbar overflow-y-auto rounded ${mainClass}`}
            style={{ ...tableContainerStyle, transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms', boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)' }}
        >
            <table className="min-w-full w-full border-collapse border-spacing-0 table">
                <thead className="bg-white table-header-group border-collapse border-spacing-0 border-b-2 border-[#EAEAEA] h-12">
                    <tr className="border-collapse outline-0 align-middle table-row text-inherit">
                        { [...headers].map((item, i: number) => (
                            <th scope='col' onClick={(e) => onSorting && onClick(e, item.value)}
                                className={`league-spartan-font sticky top-0 font-bold text-base table-cell text-[#343A40] px-4 py-1.5 ${onSorting && 'cursor-pointer'} ${isBackFilter}`} style={{ width: item.width, verticalAlign: 'inherit', textAlign: item.textAlign }} key={i}>
                                {item.value}
                                { onSorting && (
                                    <span className='absolute ml-2 top-2'>
                                        <TiArrowSortedUp className="m-0 p-0 translate-y-1" size={18} color={(sortingHeader.flag === 1 && sortingHeader.value === item.value) ? '#000' : '#A9A9A9'} />
                                        <TiArrowSortedDown className="m-0 p-0 -translate-y-1" size={18} color={(sortingHeader.flag === 2 && sortingHeader.value === item.value) ? '#000' : '#A9A9A9'} />
                                    </span>
                                )}
                            </th>
                        )) }
                    </tr>
                </thead>
                <tbody className="table-row-group">
                    { children }
                </tbody>
            </table>
        </div>
    );
}

import React from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import * as am5radar from "@amcharts/amcharts5/radar";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { IMapMarker } from "Libraries/Interfaces";
import Methods from "Libraries/CommonMethodsUI";

const ClusteredHeatMap = ({ markers, id, styles }: { markers: IMapMarker[]; id: string; styles: any; }) => {

    const getPointerColor = React.useCallback(() => {
        const totalCov = markers.map((item: any) => {

            if (item.coverages) {
              let a = item.coverages?.[0] ? item.coverages[0] : 0;
              let b = item.coverages?.[1] ? item.coverages[1] : 0;
              let c = item.coverages?.[2] ? item.coverages[2] : 0;
              let d = item.coverages?.[3] ? item.coverages[3] : 0;
              return a + b + c + d;
            } else {
              return 0;
            }
        });
        
        let filteredArry = Methods.removeDublicatesInArray(totalCov);
        filteredArry.sort((a, b) => b - a);

          // create color value stepper 
        let stepValue = 120 / (filteredArry.length - 1);
        if (stepValue === Infinity) stepValue = 120;
        
        // define colors for each marker and construct the object array
        const mappedArry: IMapMarker[] = filteredArry.map((item, idx) => {
            return { ...item, tiv: item, colorStepValue: Math.round(stepValue * idx) ?? 120 }
        });

        return mappedArry;
    }, [markers]);

    React.useLayoutEffect(() => {
        let root = am5.Root.new(id);
    
        root.setThemes([
          am5themes_Animated.new(root)
        ]);

        let chart = root.container.children.push(
            am5map.MapChart.new(root, {
              panX: "rotateX",
              panY: "translateY",
              projection: am5map.geoMercator(),
            })
          );
          
          let zoomControl = chart.set("zoomControl", am5map.ZoomControl.new(root, {}));
          zoomControl.homeButton.set("visible", true);
          
          
          // Create main polygon series for countries
          // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/
          let polygonSeries = chart.series.push(
            am5map.MapPolygonSeries.new(root, {
              geoJSON: am5geodata_worldLow,
              exclude: ["AQ"]
            })
          );
          
          polygonSeries.mapPolygons.template.setAll({
            fill:am5.color(0xdadada)
          });
          
          
          // Create point series for markers
          // https://www.amcharts.com/docs/v5/charts/map-chart/map-point-series/
          let pointSeries = chart.series.push(am5map.ClusteredPointSeries.new(root, {
            tooltip: am5.Tooltip.new(root, {
              keepTargetHover: true
            })
          }));

          // Set clustered bullet
          pointSeries.set("clusteredBullet", function(root) {
            let container = am5.Container.new(root, {
              cursorOverStyle:"pointer"
            });
          
            let circle1 = container.children.push(am5.Circle.new(root, {
              radius: 8,
              tooltipY: 0,
              fill: am5.color(0xff8c00)
            }));
          
            let circle2 = container.children.push(am5.Circle.new(root, {
              radius: 12,
              fillOpacity: 0.3,
              tooltipY: 0,
              fill: am5.color(0xff8c00)
            }));
          
            let circle3 = container.children.push(am5.Circle.new(root, {
              radius: 16,
              fillOpacity: 0.3,
              tooltipY: 0,
              fill: am5.color(0xff8c00)
            }));
          
            let label = container.children.push(am5.Label.new(root, {
              centerX: am5.p50,
              centerY: am5.p50,
              fill: am5.color(0xffffff),
              populateText: true,
              fontSize: "8",
              text: "{value}"
            }));
          
            container.events.on("click", function(e: any) {
              pointSeries.zoomToCluster(e.target.dataItem);
            });
          
            return am5.Bullet.new(root, {
              sprite: container
            });
          });
          
          // Create regular bullets
          pointSeries.bullets.push(function(root, series: any, dataItem: any) {
            let rgbColor = Methods.hslToRgb(dataItem.dataContext.colorStepValue, 100, 50);
            
            let circle = am5.Circle.new(root, {
              radius: 6,
              tooltipY: 0,
              fill: am5.color(rgbColor),
              tooltipText: "ID: [bold]{id}[/]\nLocation Name: [bold]{locName}[/]\nAddress: [bold]{data}, {state}, {postal}[/]\nCounty: [bold]{county}[/], County Tier: [bold]{countyTier}[/]\nCoverages: [bold]$ {coverageA}(Building Limit), [bold]$ {coverageB}(Other Limit), [bold]$ {coverageC}(Contents Limit), [bold]$ {coverageD}(BI Limit)[/]\nTIV: [bold]{tiv}[/]\nConstruction: [bold]{constructCode}[/], Occupancy: [bold]{occupancyCode}[/]\nNumber of Stories: [bold]{noOfStories}[/], Loc Perils: [bold]Unknown[/]\nYear Built: [bold]{yearBuilt}[/], Floor Area: [bold]{floorArea}[/], Geocode Confidence: [bold]{geoConfidance}[/]\n",
            });
          
            return am5.Bullet.new(root, {
              sprite: circle
            });
          });
        
          markers.forEach((m) => {
            addLocation(m);
          });
          
          function addLocation(item: IMapMarker) {
            pointSeries.data.push({
              geometry: { type: "Point", coordinates: [item.lng, item.lat] },
              ...item, coverageA: item.coverages[0], coverageB: item.coverages[1]
              , coverageC: item.coverages[2], coverageD: item.coverages[3], tiv: Methods.arrayValuesSum(item.coverages),
              colorStepValue: getPointerColor().find((f: any) => f.tiv === Methods.arrayValuesSum(item.coverages))?.colorStepValue
            });
          }
          
          // Make stuff animate on load
          chart.appear(1000, 100);

          return () => {
            root.dispose();
          };

    }, [id]);

    return (
        <div id={id} className="relative" style={styles}>
          <div className="absolute bg-white z-10 w-[66px] h-[19px] left-0 bottom-0 rounded-2xl"></div>
        </div>
    );
}

export const BubblesMap = ({ markers, id, styles }: { markers: IMapMarker[]; id: string; styles: any; }) => {

  const getPointerColor = React.useCallback(() => {
    const totalCov = markers.map((item: any) => {

        if (item.coverages) {
          let a = item.coverages?.[0] ? item.coverages[0] : 0;
          let b = item.coverages?.[1] ? item.coverages[1] : 0;
          let c = item.coverages?.[2] ? item.coverages[2] : 0;
          let d = item.coverages?.[3] ? item.coverages[3] : 0;
          return a + b + c + d;
        } else {
          return 0;
        }
    });
    
    let filteredArry = Methods.removeDublicatesInArray(totalCov);
    filteredArry.sort((a, b) => b - a);

      // create color value stepper 
    let stepValue = 120 / (filteredArry.length - 1);
    if (stepValue === Infinity) stepValue = 120;
    
    // define colors for each marker and construct the object array
    const mappedArry: IMapMarker[] = filteredArry.map((item, idx) => {
        return { ...item, tiv: item, colorStepValue: Math.round(stepValue * idx) ?? 120 }
    });

    return mappedArry;
  }, [markers]);

  React.useLayoutEffect(() => {
    let root = am5.Root.new(id);

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    let chart = root.container.children.push(
      am5map.MapChart.new(root, {
        panX: "rotateX",
        panY: "rotateY",
        projection: am5map.geoMercator()
      })
    );
    
    // Create series for background fill
    // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/#Background_polygon
    let backgroundSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {}));
    backgroundSeries.mapPolygons.template.setAll({
      fill: root.interfaceColors.get("alternativeBackground"),
      fillOpacity: 0,
      strokeOpacity: 0
    });
    // Add background polygo
    // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/#Background_polygon
    backgroundSeries.data.push({
      geometry: am5map.getGeoRectangle(90, 180, -90, -180)
    });
    
    // Create main polygon series for countries
    // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/
    let polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_worldLow
      })
    );
    
    polygonSeries.mapPolygons.template.setAll({
      fill: root.interfaceColors.get("alternativeBackground"),
      fillOpacity: 0.15,
      strokeWidth: 0.5,
      stroke: root.interfaceColors.get("background")
    });
    
    // Create polygon series for circles
    // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/
    let circleTemplate: any = am5.Template.new({
      tooltipText: "{name}: {value}"
    } as any);
    
    let bubbleSeries = chart.series.push(
      am5map.MapPointSeries.new(root, {
        calculateAggregates: true,
        valueField: "value",
        polygonIdField: "id"
      })
    );
    
    bubbleSeries.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          radius: 10,
          templateField: "circleTemplate"
        }, circleTemplate)
      });
    });
    
    bubbleSeries.set("heatRules", [{
      target: circleTemplate,
      min: 3,
      max: 30,
      key: "radius",
      dataField: "value"
    }]);
        
    const modifiedData = markers.map((item: IMapMarker) => {
      const colorStepValue: any =  getPointerColor().find((f: any) => f.tiv === Methods.arrayValuesSum(item.coverages))?.colorStepValue;

      return {
        id: (item.state as any)[0],
        name: (item.locName as any)[0],
        value: Methods.arrayValuesSum(item.coverages),
        circleTemplate: { fill: am5.color(Methods.hslToRgb(colorStepValue, 100, 50)) }
      }
    });
    bubbleSeries.data.setAll(modifiedData);
    
    // Add globe/map switch
    let cont = chart.children.push(am5.Container.new(root, {
      layout: root.horizontalLayout,
      x: 20,
      y: 40
    }));
    
    cont.children.push(am5.Label.new(root, {
      centerY: am5.p50,
      text: "Map"
    }));
    
    let switchButton = cont.children.push(
      am5.Button.new(root, {
        themeTags: ["switch"],
        centerY: am5.p50,
        icon: am5.Circle.new(root, {
          themeTags: ["icon"]
        })
      })
    );
    
    switchButton.on("active", function () {
      if (!switchButton.get("active")) {
        chart.set("projection", am5map.geoMercator());
        backgroundSeries.mapPolygons.template.set("fillOpacity", 0);
      } else {
        chart.set("projection", am5map.geoOrthographic());
        backgroundSeries.mapPolygons.template.set("fillOpacity", 0.1);
      }
    });
    
    cont.children.push(
      am5.Label.new(root, {
        centerY: am5.p50,
        text: "Globe"
      })
    );
    
    // Make stuff animate on load
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [id]);

  return (
    <div id={id} className="relative" style={styles}>
      <div className="absolute bg-white z-10 w-[66px] h-[19px] left-0 bottom-0 rounded-2xl"></div>
    </div>
  );
}

export const AnimatedBubblesMap = ({ markers, id, styles }: { markers: IMapMarker[]; id: string; styles: any; }) => {

  const getPointerColor = React.useCallback(() => {
    const totalCov = markers.map((item: any) => {

        if (item.coverages) {
          let a = item.coverages?.[0] ? item.coverages[0] : 0;
          let b = item.coverages?.[1] ? item.coverages[1] : 0;
          let c = item.coverages?.[2] ? item.coverages[2] : 0;
          let d = item.coverages?.[3] ? item.coverages[3] : 0;
          return a + b + c + d;
        } else {
          return 0;
        }
    });
    
    let filteredArry = Methods.removeDublicatesInArray(totalCov);
    filteredArry.sort((a, b) => b - a);

      // create color value stepper 
    let stepValue = 120 / (filteredArry.length - 1);
    if (stepValue === Infinity) stepValue = 120;
    
    // define colors for each marker and construct the object array
    const mappedArry: IMapMarker[] = filteredArry.map((item, idx) => {
        return { ...item, tiv: item, colorStepValue: Math.round(stepValue * idx) ?? 120 }
    });

    return mappedArry;
  }, [markers]);

  React.useLayoutEffect(() => {

    let root = am5.Root.new(id);
    root.setThemes([am5themes_Animated.new(root)]);
    
    let chart = root.container.children.push(am5map.MapChart.new(root, {}));
    
    let polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_worldLow
      })
    );
    
    let bubbleSeries = chart.series.push(
      am5map.MapPointSeries.new(root, {
        valueField: "value",
        calculateAggregates: true,
        polygonIdField: "id"
      })
    );
    
    let circleTemplate: any = am5.Template.new({});
    
    bubbleSeries.bullets.push(function(root, series, dataItem: any) {
      let container = am5.Container.new(root, {});
    
      let circle = container.children.push(
        am5.Circle.new(root, {
          radius: 20,
          fillOpacity: 0.7,
          fill: am5.color(Methods.hslToRgb(dataItem.dataContext.colorStepValue, 100, 50)),
          cursorOverStyle: "pointer",
          tooltipText: `{name}: [bold]$ {total}[/]`
        }, circleTemplate)
      );
    
      let countryLabel = container.children.push(
        am5.Label.new(root, {
          text: "",
          paddingLeft: 5,
          populateText: true,
          fontWeight: "bold",
          fontSize: 13,
          centerY: am5.p50
        })
      );
    
      circle.on("radius", function(radius) {
        countryLabel.set("x", radius);
      })
    
      return am5.Bullet.new(root, {
        sprite: container,
        dynamic: true
      });
    });
    
    bubbleSeries.bullets.push(function(root, series, dataItem) {
      return am5.Bullet.new(root, {
        sprite: am5.Label.new(root, {
          text: "{value.formatNumber('#.')}",
          fill: am5.color(0xffffff),
          populateText: true,
          centerX: am5.p50,
          centerY: am5.p50,
          textAlign: "center"
        }),
        dynamic: true
      });
    });
            
    // minValue and maxValue must be set for the animations to work
    bubbleSeries.set("heatRules", [
      {
        target: circleTemplate,
        dataField: "value",
        min: 10,
        max: Methods.arrayValuesSum(markers.map(v => Methods.arrayValuesSum(v.coverages)).flat(1)),
        minValue: 0,
        maxValue: Methods.arrayValuesSum(markers.map(v => Methods.arrayValuesSum(v.coverages)).flat(1)),
        key: "radius"
      }
    ]);

    const data = markers.map((item: IMapMarker) => {
      const colorStepValue: any =  getPointerColor().find((f: any) => f.tiv === Methods.arrayValuesSum(item.coverages))?.colorStepValue;

      return {
        id: (item.state as any)[0],
        name: (item.locName as any)[0],
        value: Methods.arrayValuesSum(item.coverages),
        colorStepValue: colorStepValue
      }
    });
    bubbleSeries.data.setAll(data);
    
    updateData();
    setInterval(function() {
      updateData();
    }, 2000);
    
    function updateData() {
      for (var i = 0; i < bubbleSeries.dataItems.length; i++) {
        bubbleSeries.data.setIndex(i, { value: Math.round(Math.random() * 10), total: data[i].value, id: data[i].id, name: data[i].name, colorStepValue: data[i].colorStepValue })
      }
    }

    return () => {
      root.dispose();
    };
  }, [id]);

  return (
    <div id={id} className="relative" style={styles}>
      <div className="absolute bg-white z-10 w-[66px] h-[19px] left-0 bottom-0 rounded-2xl"></div>
    </div>
  );
}

export const RotatingGlobeMap = ({ markers, id, styles }: { markers: IMapMarker[]; id: string; styles: any; }) => {

  const getPointerColor = React.useCallback(() => {
    const totalCov = markers.map((item: any) => {

        if (item.coverages) {
          let a = item.coverages?.[0] ? item.coverages[0] : 0;
          let b = item.coverages?.[1] ? item.coverages[1] : 0;
          let c = item.coverages?.[2] ? item.coverages[2] : 0;
          let d = item.coverages?.[3] ? item.coverages[3] : 0;
          return a + b + c + d;
        } else {
          return 0;
        }
    });
    
    let filteredArry = Methods.removeDublicatesInArray(totalCov);
    filteredArry.sort((a, b) => b - a);

      // create color value stepper 
    let stepValue = 120 / (filteredArry.length - 1);
    if (stepValue === Infinity) stepValue = 120;
    
    // define colors for each marker and construct the object array
    const mappedArry: IMapMarker[] = filteredArry.map((item, idx) => {
        return { ...item, tiv: item, colorStepValue: Math.round(stepValue * idx) ?? 120 }
    });

    return mappedArry;
  }, [markers]);

  React.useLayoutEffect(() => {
    let root = am5.Root.new(id);

    root.setThemes([am5themes_Animated.new(root)]);

    // Create the map chart
    let chart = root.container.children.push(am5map.MapChart.new(root, {
      panX: "rotateX",
      panY: "rotateY",
      projection: am5map.geoOrthographic()
    }));

    // Create series for background fill
    let backgroundSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {})
    );
    backgroundSeries.mapPolygons.template.setAll({
      fill: root.interfaceColors.get("alternativeBackground"),
      fillOpacity: 0.1,
      strokeOpacity: 0
    });
    backgroundSeries.data.push({
      geometry:
        am5map.getGeoRectangle(90, 180, -90, -180)
    });


    // Create main polygon series for countries
    // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/
    let polygonSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {
      geoJSON: am5geodata_worldLow 
    }));
    polygonSeries.mapPolygons.template.setAll({
      fill: root.interfaceColors.get("alternativeBackground"),
      fillOpacity: 0.15,
      strokeWidth: 0.5,
      stroke: root.interfaceColors.get("background")
    });


    // Create polygon series for projected circles
    let circleSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {}));
    circleSeries.mapPolygons.template.setAll({
      templateField: "polygonTemplate",
      tooltipText: "{name}:{value}"
    });

    // Define data
    let colors = am5.ColorSet.new(root, {});

    const data = markers.map((item: IMapMarker) => {
      const colorStepValue: any =  getPointerColor().find((f: any) => f.tiv === Methods.arrayValuesSum(item.coverages))?.colorStepValue;

      return {
        id: (item.state as any)[0],
        name: (item.locName as any)[0],
        value: Methods.arrayValuesSum(item.coverages),
        geometry: { type: "Polygon", coordinates: [item.lng, item.lat] },
        polygonTemplate: { fill: am5.color(Methods.hslToRgb(colorStepValue, 100, 50)) }
      }
    });

    let valueLow = Infinity;
    let valueHigh = -Infinity;

    for (var i = 0; i < data.length; i++) {
      let value = data[i].value;
      if (value < valueLow) {
        valueLow = value;
      }
      if (value > valueHigh) {
        valueHigh = value;
      }
    }

    // radius in degrees
    let minRadius = 0.5;
    let maxRadius = 5;

    // Create circles when data for countries is fully loaded.
    polygonSeries.events.on("datavalidated", function () {
      circleSeries.data.clear();

      for (var i = 0; i < data.length; i++) {
        let dataContext = data[i];
        let countryDataItem: any = polygonSeries.getDataItemById(dataContext.id);

        if (!countryDataItem) {
          continue;
        }

        let countryPolygon = countryDataItem.get("mapPolygon");
        let value = dataContext.value;

        let radius = minRadius + maxRadius * (value - valueLow) / (valueHigh - valueLow);

        if (countryPolygon) {
          let geometry = am5map.getGeoCircle({
            latitude: dataContext.geometry.coordinates[1],
            longitude: dataContext.geometry.coordinates[0]
          }, radius);
          circleSeries.data.push({
            name: dataContext.name,
            value: dataContext.value,
            polygonTemplate: dataContext.polygonTemplate,
            geometry: geometry
          });
        }
      }
    })


    // Make stuff animate on load
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [id]);

  return (
    <div id={id} className="relative" style={styles}>
      <div className="absolute bg-white z-10 w-[66px] h-[19px] left-0 bottom-0 rounded-2xl"></div>
    </div>
  );
}

export const GuageChart = ({ percentage, id, styles, title }: { percentage: number; id: string; styles: any; title: string; }) => {

  React.useLayoutEffect(() => {
    let root = am5.Root.new(id);

    root.setThemes([am5themes_Animated.new(root)]);

    // https://www.amcharts.com/docs/v5/charts/radar-chart/
    var chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        startAngle: 174,
        endAngle: 360
      })
    );

    chart.set("paddingLeft", 0);
    chart.set("paddingRight", 0);
    chart.set("paddingTop", 0);
    chart.set("paddingBottom", 0);

    // Create axis and its renderer
    // https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Axes
    var axisRenderer = am5radar.AxisRendererCircular.new(root, {
      innerRadius: -10,
      strokeOpacity: 1,
      strokeWidth: 10,
      strokeGradient: am5.LinearGradient.new(root, {
        rotation: 0,
        stops: [
          { color: am5.color(0xfb7116) },
          { color: am5.color(0xf6d32b) },
          { color: am5.color(0xf4fb16) },
          { color: am5.color(0x19d228) }
        ]
      })
    });

    var xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        max: 100,
        strictMinMax: true,
        renderer: axisRenderer
      })
    );

    // Hide labels
    xAxis.get("renderer").labels.template.setAll({
      visible: false
    });

    // Add clock hand
    // https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Clock_hands
    var axisDataItem: any = xAxis.makeDataItem({});
    axisDataItem.set("value", 8);

    var bullet = axisDataItem.set("bullet", am5xy.AxisBullet.new(root, {
      sprite: am5radar.ClockHand.new(root, {
        radius: am5.percent(93),
        // innerRadius: am5.percent(0),
        // width: am5.percent(13),
        // topWidth: 13,
        pinRadius: am5.percent(11)
      })
    }));

    xAxis.createAxisRange(axisDataItem);
    axisDataItem.get("grid").set("visible", false);

    // Add center label
    var label = chart.seriesContainer.children.push(
      am5.Label.new(root, {
        text: title, // Display percentage
        fontSize: 13,
        centerX: am5.percent(50),
        centerY: am5.percent(130),
        textAlign: "center",
        fill: am5.color("rgb(229 231 235)")
      })
    );

    chart.appear(1000, 100);

    setTimeout(() => {
      axisDataItem.animate({
        key: "value",
        to: percentage,
        duration: 3000,
        easing: am5.ease.out(am5.ease.cubic)
      });
    }, 1200);

    return () => {
      root.dispose();
    };
  }, [id, title]);

  return (
    <div id={id} className="relative" style={styles}>
      <div id={id} className="absolute bg-white z-10 w-[66px] h-[19px] left-0 bottom-0 rounded-2xl"></div>
    </div>
  );
}

export default ClusteredHeatMap;
import { ICommentItem, ICompany, ICompanyInvoice, IDashboardCardData, IEmailReply, IExposureFile, IHistoryItem, IPendingSubmissionAttachment, IPendingVersionListItem, IReportData, IReportedConstruction, IReportedOccupancy, IResponse, IStpListItem, ISubmissionListItem, IUserProfile, IVersionListItem, IVersionStatus } from "Libraries/Interfaces";
import ApiService from "./ApiService";
import ApiUrls from "./ApiUrls";
import { AxiosResponse } from "axios";

class Request{
    public async signUpRequest(data: any): Promise<AxiosResponse> {
        try {
            return await ApiService.postRequest(ApiUrls.signUpUrl, data, false);
        } catch (error) {
            throw error;
        }
    };
 
    public async signInRequest(data: any): Promise<AxiosResponse> {
        try {
            return await ApiService.postRequest(ApiUrls.loginUrl, data, false);
        } catch (error) {
            throw error;
        }
    };

    public async refreshTokenRequest(data: any): Promise<AxiosResponse> {
        try {
            return await ApiService.postRequest(ApiUrls.tokenRefreshUrl, data, false);
        } catch (error) {
            throw error;
        }
    };

    public async getSubmissionRequest(data: any, params: string): Promise<AxiosResponse<{ results: ISubmissionListItem[]; count: number; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.getSubmissionUrl + params, data);
        } catch (error) {
            throw error;
        }
    };

    public async getASubmissionRequest(controlNumber: string): Promise<AxiosResponse<ISubmissionListItem, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.getSubmissionUrl + `${controlNumber}/`, undefined);
        } catch (error) {
            throw error;
        }
    };

    public async getSubmissionVersionsRequest(params: string): Promise<AxiosResponse<{ results: IVersionListItem[]; count: number; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.getSubmissionVersionsUrl + params, undefined);
        } catch (error) {
            throw error;
        }
    };

    public async getPendingSubmissionVersions(params: string): Promise<AxiosResponse<{ results: IPendingVersionListItem[]; count: number; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.pendingVersions + params, undefined);            
        } catch (error) {
            throw error;
        }
    };

    public async getPendingSubmissionVersionAttachments(params: string): Promise<AxiosResponse<{ results: IPendingSubmissionAttachment[]; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.pendingVersionAttachments + params, undefined);
        } catch (error) {
            throw error;
        }
    };

    public async getCompaniesList(): Promise<AxiosResponse<{ results: ICompany[]; count: number; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.listCompanies, undefined);
        } catch (error) {
            throw error;
        }
    };

    public async getVersionComments(params: string): Promise<AxiosResponse<ICommentItem[], any>> {
        try {
            return await ApiService.getRequest(ApiUrls.versionComments + params, undefined);
        } catch (error) {
            throw error;
        }
    };

    public async getVersionHistories(params: string): Promise<AxiosResponse<{ results: IHistoryItem[]; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.fetchHistory + params, undefined);
        } catch (error) {
            throw error;
        }
    };

    public async getVersionExposureFiles(params: string): Promise<AxiosResponse<IExposureFile[], any>> {
        try {
            return await ApiService.getRequest(ApiUrls.exposureFiles + params, undefined);
        } catch (error) {
            throw error;
        }
    };

    public async getVersionExposureTemplates(params: string): Promise<AxiosResponse<{ response: { file_name: string; type: string; original_file_name?: string; }[]; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.exposureTemplates + params, undefined);
        } catch (error) {
            throw error;
        }
    };

    public async getDashboardCounts(params: string): Promise<AxiosResponse<IDashboardCardData, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.dashboardCounts + params, undefined);
        } catch (error) {
            throw error;
        }
    };

    public async getReportedConstructions(params: string): Promise<AxiosResponse<{ count: number; results: IReportedConstruction[]; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.reportedConstructions + params, undefined);
        } catch (error) {
            throw error;
        }
    };

    public async getReportedOccupancies(params: string): Promise<AxiosResponse<{ count: number; results: IReportedOccupancy[]; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.reportedOccupancies + params, undefined);
        } catch (error) {
            throw error;
        }
    };

    public async generateInvoice(params: string): Promise<AxiosResponse<ICompanyInvoice | null, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.generateInvoice + params, undefined);
        } catch (error) {
            throw error;
        }
    };

    public async getCompanies(): Promise<AxiosResponse<{ company_names: string[]; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.getCompanies, undefined);
        } catch (error) {
            throw error;
        }
    };

    public async approvePendingVersion(data: { pending_submission_version_id: number; }): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.approvePendingVersion, data, true);
        } catch (error) {
            throw error;
        }
    };

    public async approveAsControlNumber(data: { pending_submission_version_id: number; }): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.approveAsControlNumber, data, true);
        } catch (error) {
            throw error;
        }
    };

    public async addVersionComments(data: { comment: string; submission_version_id: number; }): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.versionComments, data, true);
        } catch (error) {
            throw error;
        }
    };

    public async updateStatus(data: IVersionStatus): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.updateStatus, data, true);
        } catch (error) {
            throw error;
        }
    };

    public async archiveSubmission(data: { control_number: string; }): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.archiveSubmission, data, true);
        } catch (error) {
            throw error;
        }
    };

    public async dublicateVersion(data: { control_number: string; version: number; }): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.dublicateVersion, data, true);
        } catch (error) {
            throw error;
        }
    };

    public async emailReplyList(params: string): Promise<AxiosResponse<IEmailReply[], any>> {
        try {
            return await ApiService.getRequest(ApiUrls.emailReplyList + params, undefined);
        } catch (error) {
            throw error;
        }
    };

    public async getDTReportJson(params: string): Promise<AxiosResponse<{ data_transparency_report_json: IReportData; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.getDTReportJson + params, undefined);
        } catch (error) {
            throw error;
        }
    };

    public async archiveVersion(data: { control_number: string; versions: number[]; }): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.archiveVersion, data, true);
        } catch (error) {
            throw error;
        }
    };

    public async resetPasswordTryRequest(data: any): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.resetPasswordRequestUrl, data, false);
        } catch (error) {
            throw error;
        }
    };

    public async resetPasswordConfirmRequest(data: any): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.resetPasswordConfirmUrl, data, false);
        } catch (error) {
            throw error;
        }
    };

    public async createLogRequest(data: any): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.createLogUrl, data, true);
        } catch (error) {
            throw error;
        }
    };

    public async sendUWEmail(data: any): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequestWithMultiPart(ApiUrls.uwEmail, data);
        } catch (error) {
            throw error;
        }
    };

    public async changePasswordRequest(data: any): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.putRequest(ApiUrls.changePasswordUrl, data);
        } catch (error) {
            throw error;
        }
    };

    public async submissionAssignRequest(data: { submission_version_id: number; }): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.putRequest(ApiUrls.submissionAssign, data);
        } catch (error) {
            throw error;
        }
    };

    public async submissionUpdateRequest(data: any): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.putRequest(ApiUrls.submissionUpdate, data);
        } catch (error) {
            throw error;
        }
    };

    public async submissionGetRequest(data: any): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.submissionGetUpdate, data);
        } catch (error) {
            throw error;
        }
    };

    public async exportUserGetRquest(data: { control_number: string; }): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.exportTemplate, data);
        } catch (error) {
            throw error;
        }
    };

    public async getSTPSovsLists(data: any, params: string): Promise<AxiosResponse<{ count: number; results: IStpListItem[]; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.getSTPSovsLists + params, data);
        } catch (error) {
            throw error;
        }
    };

    public async getUserDetails(): Promise<AxiosResponse<IUserProfile | undefined, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.getUserDetails, {});
        } catch (error) {
            throw error;
        }
    };

    public async postSovForSTPProcess(data: any): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequestWithMultiPart(ApiUrls.postSovForSTPProcess, data);
        } catch (error) {
            throw error;
        }
    };

    public async getConstOccPieChartData(data: any): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.constOccPieChartData, data, true);
        } catch (error) {
            throw error;
        }
    };

    public async getPasswordRules(): Promise<AxiosResponse<{ password_rules: string[]; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.getPasswordRules, undefined, true);
        } catch (error) {
            throw error;
        }
    };

    public async reportMapData(params: string): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.reportMapData + params, undefined);
        } catch (error) {
            throw error;
        }
    };

    public async reportGeoCodeData(params: string): Promise<AxiosResponse<{ data: { [key: string]: { count: number; percentage: number; } }}, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.reportGeoCodeData + params, undefined);
        } catch (error) {
            throw error;
        }
    };

    public async reportConstructionsData(params: string): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.reportConstructionsData + params, undefined);
        } catch (error) {
            throw error;
        }
    };

    public async reportOccupancyData(params: string): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.reportOccupancyData + params, undefined);
        } catch (error) {
            throw error;
        }
    };

    public async reportCoverageData(params: string): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.reportCoverageData + params, undefined);
        } catch (error) {
            throw error;
        }
    };

    public async reportDataQuality(params: string): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.reportDataQuality + params, undefined);
        } catch (error) {
            throw error;
        }
    };

    public async reportDataCompleteness(params: string): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.reportDataCompleteness + params, undefined);
        } catch (error) {
            throw error;
        }
    };

    public async reportDataCoverageByGeography(params: string): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.reportDataCoverageByGeography + params, undefined);
        } catch (error) {
            throw error;
        }
    };
}

const ApiRequest = new Request();

export default ApiRequest;

import * as am5percent from "@amcharts/amcharts5/percent";
import * as am5 from "@amcharts/amcharts5";

export function circularLabelsInsideLargeWidthSlice(series: am5percent.PieSeries, match: number): void {
    series.labels.template.adapters.add("y", function(y, target: any) {
        var dataItem = target.dataItem;
        if (dataItem) {
          var tick = dataItem.get("tick");
          if (tick) {
            if (dataItem.get("valuePercentTotal") < match) {
              target.set("forceHidden", true);
              tick.set("forceHidden", true);
            }
            else {
              target.set("forceHidden", false);
              tick.set("forceHidden", false);
            }
          }
          return y;
        }
    });
}

export function activeOneSliceRemainingInActive(series: am5percent.PieSeries, onSelection?: (obj: any) => void): void {
  series.slices.template.events.on("click", function(ev) {
    const clickedSlice = ev.target;

    // Call the callback function if provided
    if (onSelection) {
      onSelection(clickedSlice.dataItem?.dataContext);
      
      series.slices.each(function(slice) {
        if (slice === clickedSlice) {
          slice.set("active", true);
        } else {
          slice.set("active", false);
        }
      });

      disabledRemainingSlices(clickedSlice, series.slices);
    } else {
      // Iterate over all slices
      series.slices.each(function(slice) {
        if (slice != ev.target && slice.get("active")) {
          slice.set("active", false);
        }
      });
    }    
  });
}

export function disabledRemainingSlices(clickedSlice: any, series: any): void {
  // Iterate over all slices
  series.each(function(slice: any) {
    if (slice === clickedSlice) {
      slice.set("disabled", false);
      slice.set("active", true);
    } else {
      slice.set("disabled", true);
      slice.set("active", true);
    }
  });

  series.template.states.create("disabled", {
    fillOpacity: 0.9, // Make the disabled slice more transparent
    fill: am5.color('#f1f1f1'), // Change color for the disabled slice,
  });
}

const ACCESS_TOKEN_KEY = 'authenticationAccessToken',
      REFRESH_TOKEN_KEY = 'authenticationRefreshToken';

class Service {
    public getAccessToken(): string | null {
        return localStorage.getItem(ACCESS_TOKEN_KEY);
    }

    public setAccessToken(token: string): void {
        localStorage.setItem(ACCESS_TOKEN_KEY, token);
    }

    public getRefreshToken(): string | null {
        return localStorage.getItem(REFRESH_TOKEN_KEY);
    }

    public setRefreshToken(token: string): void {
        localStorage.setItem(REFRESH_TOKEN_KEY, token);
    }

    public clearTokens(): void {
        localStorage.clear();
        sessionStorage.clear();
    }

    public logoutWithToken(): void {
        this.clearTokens();
    }
}

export const TokenService = new Service();


import React from 'react';
import {RiCheckboxCircleFill, RiCloseCircleFill, RiInformationFill} from "react-icons/ri";
import {popupMessageEnum} from "Libraries/Enums";

// contracts
interface Props {
    message: string;
    icon: string;
}

export const Popup: React.FC<Props> = ({message, icon}) => {

    return (
        <div className="flex flex-row items-center">
            {  icon === popupMessageEnum.success ?
                 <RiCheckboxCircleFill className="mr-4 flex items-center h-12 w-12 text-green-600" />
             : icon === popupMessageEnum.error ?
                 <RiCloseCircleFill className="mr-4 flex items-center h-12 w-12 text-red-600" />
             : <RiInformationFill className="mr-4 flex items-center h-12 w-12 text-orange-600" />
            }
             {message}
        </div>
    );
};

import {TokenService} from 'Libraries/TokenService';
import httpRequest from './Interceptor';

class Service{

    public async getRequest(url: string, data: any, withNotToken?: boolean){

        if (withNotToken) {
            return httpRequest.get(url, {
                headers: { Authorization: '' },
                params: data,
            });
        }

        return httpRequest.get(url, {
            headers: {
                Authorization: `Bearer ${TokenService.getAccessToken()}`,
            },
            params: data,
        });
    };

    public async postRequestWithMultiPart(url: string, body: any): Promise<any> {
        return httpRequest.post(url, body, {
            headers: {
                Authorization: `Bearer ${TokenService.getAccessToken()}`,
                "Content-Type": "multipart/form-data",
            },
        });
    };

    public async postRequest(url: string, body: any, withToken: boolean){
        if (withToken) {
            return httpRequest.post(url, body, {
                headers: {
                    Authorization: `Bearer ${TokenService.getAccessToken()}`,
                },
            });
        }

        return httpRequest.post(url, body, {
            headers: {
                Authorization: '',
            },
        });
    };

    public async putRequest(url: string, body:any){
       return httpRequest.put(url,  body, {
           headers: {
               Authorization: `Bearer ${TokenService.getAccessToken()}`,
           },
       });
    };

    public async patchRequest(url: string, id: string, body:any){
       return httpRequest.patch(`${url}${id}/`, body, {
           headers: {
               Authorization: `Bearer ${TokenService.getAccessToken()}`,
           },
       });
    };
}

const ApiService = new Service();

export default ApiService;

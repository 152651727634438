import * as React from 'react';

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw } from 'draft-js';
import Methods from 'Libraries/CommonMethodsUI';

interface IProps { value: string | undefined; setValue: (v: string) => void; id: string; }

export default function TinyMceEditor(props: IProps): JSX.Element {

  const ref: any = React.useRef(null);
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty());

  React.useEffect(() => {
    if (ref.current) {
      Methods.scrollToSection("exposure-modal-top");
    }
  }, []);

  const onChange = (editorState: any): void => {
    const s = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setEditorState(editorState);
    props.setValue(s);
  };

  return (
    <div className="text-editor w-full bg-inherit p-0 border border-gray-200 text-sm text-black font-normal placeholder:text-black placeholder:opacity-50 focus:ring-0" id={props.id}>
      <Editor
        ref={ref}
        placeholder='Body Text'
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onChange}
        editorStyle={{ padding: 10, height: 300, overflow: 'hidden' }}
        localization={{
          locale: 'en',
        }}
      />
    </div>
  );
}

import React, {useEffect, useState} from "react";

import {Button} from "Components/Button/Button";
import ApiRequest from "Services/ApiRequest";
import CommonMethodsUI from "Libraries/CommonMethodsUI";
import Constants from "Libraries/Constants";
import {List} from "react-content-loader";
import {ISubmission} from "Libraries/Interfaces";

interface Props {
    openRowModal: any;
    setOpen: (e?: boolean) => void;
    isStatusLog?: boolean;
    screen?: string;
}

const RowClickModal:React.FC<Props> = (props) => {
    const {isStatusLog, setOpen, openRowModal, screen} = props;
    const  { uw_name, uw_email, version, sov_filename, name_insured, status, id, 
        name, code, key, custom, air, rms } = openRowModal;
    const [ submissionLogs, setSubmissionLogs ] = useState<{data: any, error: string}>({data: [], error: ''});

   const rowDashboardSubmissions = [
       { id: 1, name: uw_name, key: 'Name' },
       { id: 2, name: uw_email, key: 'Email' },
       { id: 3, name: version, key: 'Version' },
       { id: 4, name: sov_filename, key: 'SOV Filename' },
       { id: 5, name: name_insured, key: 'Name Insured' },
       { id: 6, name: status, key: 'Status' },
   ];

    const rowCompanySubmissions = [
        { id: 1, name: id, key: 'Id' },
        { id: 2, name: name, key: 'Company' },
        { id: 3, name: code, key: 'Code' },
        { id: 4, name: key, key: 'Key' },
        { id: 5, name: custom, key: 'Custom Template' },
        { id: 6, name: air, key: 'AIR Template' },
        { id: 7, name: rms, key: 'RMS Template' },
    ];

    const rowSubmissions = screen === 'dashboard' ? rowDashboardSubmissions : rowCompanySubmissions;

    async function initialRun(): Promise<void> {
        try {
            const res = await ApiRequest.submissionGetRequest({submission_id: id});
            setSubmissionLogs({ data: res.data?.results, error: '' });
        }
        catch (err) {
            setSubmissionLogs({data: [], error: 'No Logs Found'});
        }
    }

    useEffect(() => {
        if(isStatusLog) initialRun();
    },[]);

    return(
        <div>
            <div className="font-bold text-2xl mb-2 text-center"> {screen === 'dashboard' ? 'Submission Detail' : screen === 'company' ? 'Company Detail' : 'Status Logs'} </div>

            {!isStatusLog ?
             <div className={'flex flex-row flex-wrap w-full'}>
                    {rowSubmissions.map((item, i)=>(
                        <div  key={i} className="w-1/2 xs:w-full rounded overflow-hidden shadow-lg">
                            <div className="px-6 py-4">
                                <div className="font-bold text-xl mb-2">{item.key}:</div>
                                <p className="text-gray-700 text-base">
                                    {   screen === 'company' && i >= 4
                                        ? CommonMethodsUI.answerBoolean(item.name)
                                        : CommonMethodsUI.noDataAvailable(item.name)
                                    }
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
              :
                <div>
                    {submissionLogs.data.length > 0 &&
                    <div>
                        <span className={'ml-2 font-medium'}>Control Number: </span>
                        <span>{submissionLogs.data?.[0]?.control_number}</span>
                    </div>
                    }
                    <div className={`mt-4 flow-root ${submissionLogs.data.length < 6 ? 'h-auto' : 'h-96'} overflow-y-auto`}>
                        <div className="mx-1">
                            <div className="inline-block min-w-full py-2 align-middle">
                                <table className="min-w-full border rounded-md border-gray-300 border-separate border-spacing-0">
                                    <thead>
                                    <tr>
                                        {Constants.statusLogsHeaders().map((item,i)=>(
                                            <th scope="col"
                                                className={`sticky top-0 z-10 pl-4 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 text-left 
                                                         text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter`}
                                            >
                                                <div className="flex flex-row items-center">
                                                    {item.name}
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {(!submissionLogs.data.length && !submissionLogs.error) ? <><List /><List /></>
                                        : (!submissionLogs.data.length && submissionLogs.error) ? <div className={"text-red-500 text-lg p-4"}>No Logs Found</div>
                                            : submissionLogs.data.length > 0 ? submissionLogs.data.sort((a: any,b: any)=> b.created_on - a.created_on).map((person: ISubmission, personIdx: number) => (
                                                <tr className={'cursor-pointer hover:bg-gray-100'} key={person.id} >
                                                    {Constants.statusLogsHeaders(person).map((item,i) => (
                                                        <td className={CommonMethodsUI.classNames(
                                                            personIdx !== person?.length - 1 ? 'border-b border-gray-200' : '',
                                                            'whitespace-nowrap py-4 pl-4 text-sm font-medium text-gray-900'
                                                        )}>
                                                            {CommonMethodsUI.noDataAvailable(item.data)}
                                                        </td>
                                                    ))}
                                                </tr>
                                            )) : null}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className=" py-3 sm:flex sm:flex-row-reverse">
                <Button
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    title={'Cancel'}
                />
            </div>

        </div>
    );
}

export default RowClickModal;

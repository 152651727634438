import { Fragment, useState, useEffect } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Tabs } from 'Components/Tabs/Tabs';
import { CommentTab } from './Comments';
import { HistoryTab } from './History';
import OutsideClickHandler from 'react-outside-click-handler';

interface IProps {
    open: boolean; 
    setOpen: (f: boolean) => void;
    controlNumber: string;
    selectedVersion: any;
    bottomClass: string;
}

export const RightSidePanel: React.FC<IProps> = ({ 
    open, setOpen, selectedVersion, controlNumber, bottomClass
}) => {
    
    const [activeTab, setActiveTab] = useState<number>(1);

    useEffect(() => {
        if (selectedVersion && activeTab !== 1) {
            setActiveTab(1);
        }
    }, [selectedVersion]);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-0" onClose={() => null}>
                <div className="fixed top-0 right-0 bottom-0 w-4/5" />

                <div className="fixed top-0 right-0 bottom-0 overflow-hidden">
                    <div className="absolute top-0 right-0 bottom-0 overflow-hidden">
                        <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
                            <div className={`pointer-events-none fixed ${bottomClass} right-0 flex max-w-full pl-10`} style={{ top: 64 }}>
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                <Dialog.Panel className="pointer-events-auto w-80">
                                    <div className="flex h-full flex-col overflow-y-auto remove-scrollbar bg-white border-l-2 border-b-2 border-primary-grey">
                                        <div className="px-4 sticky top-0 z-10 backdrop-blur backdrop-filter bg-white bg-opacity-75 py-2">
                                            <div className="flex items-center justify-between">
                                                <Dialog.Title className="text-base font-semibold leading-5 text-gray-900">
                                                    <Tabs activeClassName="text-sm mr-4 text-black" inActiveClassName="text-sm mr-4" onChange={setActiveTab} active={activeTab} tabs={[ { id: 1, name: 'Comments' }, { id: 2, name: 'History' } ]} />
                                                </Dialog.Title>
                                                <div className="ml-3 flex h-5 items-center">
                                                    <button
                                                        type="button"
                                                        className="relative text-gray-400 hover:text-gray-500 focus:outline-none"
                                                        onClick={() => setOpen(false)}
                                                    >
                                                        <span className="absolute -inset-2.5" />
                                                        <span className="sr-only">Close panel</span>
                                                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="relative mt-4 flex-1 px-4">
                                            { activeTab === 1 ? <CommentTab versionId={selectedVersion?.version} id={selectedVersion?.id} controlNumber={controlNumber} /> : <HistoryTab versionId={selectedVersion?.version} controlNumber={controlNumber} /> }
                                        </div>
                                    </div>
                                </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </OutsideClickHandler>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

import * as React from "react";

import { IDataCompleteColumnDetails, IDataCompleteness, IDataMapped, IDataQuality } from "Libraries/Interfaces";
import {
  DataMappedContainer,
  DoughnutCharts
} from "Components/Charts/ChartsCommon";
import CustomTable2 from 'Components/CustomTable/CustomTable';

interface IDataMappedProps {
  dataMapped: IDataMapped | undefined;
  title: string;
  isUpdation?: number;
}

interface IDataCompletenessProps {
  dataCompleteness: IDataCompleteness | undefined;
  title: string;
  isUpdation?: number;
}

interface IDataQualityProps {
  dataQuality: IDataQuality | undefined;
  title: string;
  isUpdation?: number;
}

const tableContainerStyle = { maxHeight: "auto", overflow: "hidden" };

export function DataMappedForReport(props: IDataMappedProps): JSX.Element | null {
  const { dataMapped, title, isUpdation } = props as any;

  const chartRef = React.useRef<any>();

  const getColumnLengths = (arr: string[]): string => {
    const str: string = arr.length === 1 ? "Column" : "Columns";
    return `${arr.length} ${str}`;
  };

  return React.useMemo(() => {
    const chartArray = [
      { id: 0, title: "Auto Mapped", label: "Auto Mapped" },
      { id: 1, title: "Manually Mapped", label: "Manually Mapped" },
      { id: 2, title: "Not Mapped", label: "Not Mapped" },
    ];

    const dataMappedUpdated = dataMapped?.results ? dataMapped?.results : dataMapped;

    const totalColumns: number = dataMappedUpdated?.data_for_chart?.auto_mapped_columns?.length + dataMappedUpdated?.data_for_chart?.manual_mapped_columns?.length + dataMappedUpdated?.data_for_chart?.uninterpreted_data_columns?.length;
    const indexes: number[] = [];
    for (let i = 0; i < totalColumns; i++) {
      indexes.push(i);
    }

    if (dataMappedUpdated) {
      return (
        <DataMappedContainer
          title={title} caption="of your data was mapped" indicator="DM"
          score={dataMappedUpdated?.data_mapped_score}
        >
          <br />
          <DoughnutCharts isUpdation={isUpdation} chartDivId="doughnut-charts-dm" chartArray={chartArray} chartRef={chartRef} data={dataMappedUpdated?.data_for_chart?.percentages}  />  

          <CustomTable2 tableContainerStyle={{ ...tableContainerStyle, marginTop: '10px' }} headers={[
            { value: getColumnLengths(dataMappedUpdated?.data_for_chart?.auto_mapped_columns), textAlign: "center", width: "33.33%" },
            { value: getColumnLengths(dataMappedUpdated?.data_for_chart?.manual_mapped_columns), textAlign: "center", width: "33.33%" },
            { value: getColumnLengths(dataMappedUpdated?.data_for_chart?.uninterpreted_data_columns), textAlign: "center", width: "33.33%" }
          ]}>
            { 
              indexes.map((i: number, index: number) => {
                const a = dataMappedUpdated?.data_for_chart?.auto_mapped_columns[i];
                const b = dataMappedUpdated?.data_for_chart?.manual_mapped_columns[i];
                const c = dataMappedUpdated?.data_for_chart?.uninterpreted_data_columns[i];
                
                if (a || b || c) {
                    return (
                    <tr key={index} className="border-collapse outline-0 align-middle table-row text-inherit">
                        {[a, b, c].map((val: any, index1: number) => (
                        <td key={index1} className={`leading-6 text-center text-sm table-cell text-black border-b border-[#f1f1f1] px-4 py-1.5`}>
                            {val ? val : ""}
                        </td>
                        ))}
                    </tr>
                    );
                }   
                
                return null;
              })
            }    
          </CustomTable2>          
        </DataMappedContainer>
      );
    }

    return null;
  }, [dataMapped, chartRef, title, isUpdation]);
}

export function DataCompletenessForReport(
    props: IDataCompletenessProps
  ): JSX.Element | null {
    const { dataCompleteness, title, isUpdation } = props as any;
  
    const chartRef = React.useRef<any>();
  
    return React.useMemo(() => {
      const chartArray = [
        { id: 0, title: "Reported", label: "Reported" },
        { id: 1, title: "Reported as Unknown", label: "Reported as Unknown" },
        { id: 2, title: "Not Reported", label: "Not Reported" },
      ];
  
      const dataCompletenessUpdated: IDataCompleteness = dataCompleteness?.results
        ? dataCompleteness?.results
        : dataCompleteness;
  
      if (dataCompletenessUpdated) {
        return (
          <DataMappedContainer
            title={title} indicator="DC"
            score={dataCompletenessUpdated?.data_completeness_score}
            caption="of the data required by CAT Models was reported"
          >
            <br />
            <DoughnutCharts isUpdation={isUpdation} chartDivId="doughnut-charts-dc" chartArray={chartArray} chartRef={chartRef} data={dataCompletenessUpdated?.data_for_chart?.percentages}  />

            <CustomTable2 tableContainerStyle={{ ...tableContainerStyle, marginTop: '10px' }} headers={[
              { value: "Column Name", textAlign: "left", width: "25%" },
              { value: "% Reported", textAlign: "center", width: "25%" },
              { value: "% Reported as Unknown", textAlign: "center", width: "25%" },
              { value: "% Not Reported", textAlign: "center", width: "25%" }
            ]}>
                {Object.entries(dataCompletenessUpdated?.column_details).map(
                    (
                      [key, value]: [string, IDataCompleteColumnDetails],
                      index: number
                    ) => (
                        <tr key={index} className="border-collapse outline-0 align-middle table-row text-inherit">
                        {[
                          key,
                          value.reported,
                          value.reported_as_unknown,
                          value.not_reported,
                        ].map((val: any, index1: number) => (
                          <td
                            key={index1} 
                            className={`leading-6 ${index1 > 0 ? "text-center" : "text-left"} w-1/4 text-sm table-cell text-black border-b border-[#f1f1f1] px-4 py-1.5`}
                          >
                            {val}
                            {index1 > 0 ? "%" : ""}
                          </td>
                        ))}
                      </tr>
                    )
                  )}
            </CustomTable2>
          </DataMappedContainer>
        );
      }
  
      return null;
    }, [dataCompleteness, chartRef, title, isUpdation]);
}

export function DataQualityForReport(props: IDataQualityProps): JSX.Element | null {
    const { dataQuality, title, isUpdation } = props as any;
  
    const chartRef = React.useRef<any>();
  
    return React.useMemo(() => {
      const chartArray = [
        { id: 0, title: "Reported", label: "Reported" },
        { id: 1, title: "Reported as Unknown", label: "Reported as Unknown" },
        { id: 2, title: "Not Reported", label: "Not Reported" },
      ];
  
      const dataQualityUpdated: IDataQuality = dataQuality?.results
        ? dataQuality?.results
        : dataQuality;
  
      if (dataQualityUpdated) {
        return (
          <DataMappedContainer
            title={title} caption="Data Quality Score" indicator="DQ"
            score={dataQualityUpdated?.data_quality_score}
          >
            <br />
            <DoughnutCharts isUpdation={isUpdation} chartDivId="doughnut-charts-dq" chartArray={chartArray} chartRef={chartRef} data={dataQualityUpdated?.data_for_chart?.percentages}  />
            
            <CustomTable2 tableContainerStyle={{ ...tableContainerStyle, marginTop: '10px' }} headers={[
              { value: "Column Name", textAlign: "left", width: "25%" },
              { value: "% Reported", textAlign: "center", width: "25%" },
              { value: "% Reported as Unknown", textAlign: "center", width: "25%" },
              { value: "% Not Reported", textAlign: "center", width: "25%" }
            ]}>
                {Object.entries(dataQualityUpdated?.column_details).map(
                    (
                      [key, value]: [string, IDataCompleteColumnDetails],
                      index: number
                    ) => (
                        <tr key={index} className="border-collapse outline-0 align-middle table-row text-inherit">
                        {[
                          key,
                          value.reported,
                          value.reported_as_unknown,
                          value.not_reported,
                        ].map((val: any, index1: number) => (
                          <td
                            className={`leading-6 ${index1 > 0 ? "text-center" : "text-left"} w-1/4 text-sm table-cell text-black border-b border-[#f1f1f1] px-4 py-1.5`}
                            key={index1}
                          >
                            {val}
                            {index1 > 0 ? "%" : ""}
                          </td>
                        ))}
                      </tr>
                    )
                  )}
            </CustomTable2>
          </DataMappedContainer>
        );
      }
  
      return null;
    }, [dataQuality, chartRef, title, isUpdation]);
}

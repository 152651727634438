import { IS_LOGIN, IS_LOGOUT, SET_USER_DETAILS } from "Redux/Types";
import { ILoginState, IAction } from "Libraries/State.Interfaces";

const initialState: ILoginState = {
    isLoggedIn: false,
    user: undefined
};

export const LoginReducer = (state = initialState, action: IAction): ILoginState => {

    const { payload, type } = action;

    switch(type){

        case IS_LOGIN: {
            return {
                ...state,
                isLoggedIn: true,
                user: payload
            };
        }

        case SET_USER_DETAILS: {
            return {
                ...state,
                user: payload
            };
        }

        case IS_LOGOUT: {
            return {
                ...initialState
            };
        }

        default:
            return state;

    }

};

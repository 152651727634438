import React from 'react';

import {useSelector} from "react-redux";
import {IUserProfile} from "Libraries/Interfaces";
import Constants from "Libraries/Constants";
import { BsBuildingsFill } from 'react-icons/bs';

export default function Profile(): JSX.Element {

    const userDetail: IUserProfile = useSelector((state: any) => state['loginReducer'].user);

    return(
        <div className="flex flex-col">
            <h6 className="h-12 items-center inline-flex justify-center text-base text-black font-semibold">User Profile</h6>

            <nav className="mb-2">
                {Constants.userProfile(userDetail).filter(ud => ud.show).map((item) => (
                    <div key={item.id} className={`group w-full inline-flex justify-start items-center pl-3 h-14 text-[$676767]`}>
                        <div className="mr-4 h-5 w-5 flex-shrink-0"> {item.icon} </div>
                        <div className={'text-xs'}> {item.data} </div>
                    </div>
                ))}

                <div className={`group inline-flex justify-start items-center pl-3 h-14 text-[$676767] w-full`}>
                    <div className="mr-4 h-5 w-5 flex-shrink-0"> <BsBuildingsFill className="w-5 h-5 text-[#676767]" /> </div>
                    <div className='text-xs'> Assigned Companies </div>
                </div>
                <ul className='list-disc list-inside pl-12'>
                    { userDetail?.assigned_companies?.map((c) => (
                        <li className='text-xs'> {c?.company_name} </li>
                    )) }
                </ul>
            </nav>
        </div>
    );
}

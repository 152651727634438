import * as React from 'react';

import SidePanel from "Components/SidePanel/SidePanel";
import TopBar from "Components/TopBar/TopBar";
import Modal from 'Components/Modal/Modal';
import { modalScreensEnum } from 'Libraries/Enums';
import ChangePasswordScreen from 'Screens/ChangePassword/Content';
import { IRootReducer } from "Libraries/State.Interfaces";
import { useDispatch, useSelector } from 'react-redux';
import { sideBarONOFFAction } from 'Redux/Action';
import { Images } from 'Libraries/Images';

export default function MasterLayout(props: { 
    children: JSX.Element; className: string; mainClassName?: string; userEmail: string; navigate?: (() => void | undefined) | undefined; mainTitle?: string; 
    isReportTab?: boolean;
}): JSX.Element {

    const dispatch = useDispatch();

    const open: boolean = useSelector((state: IRootReducer) => state.activityReducer.sideBarState);
    const loader: boolean = useSelector((state: IRootReducer) => state.activityReducer.loader);

    const [openModal, setOpenModal] = React.useState<{ flag: boolean, key: string }>({flag: false, key: ''});

    return(
        <React.Fragment>
            <SidePanel 
                isProfile={openModal.key === modalScreensEnum[modalScreensEnum.profile]} 
                isSideBarOpen={open} setOpen={(f: boolean) => {
                    setOpenModal({flag: false, key: ''});
                    dispatch(sideBarONOFFAction(f));
                }}
            />
            <div className={`flex flex-1 ${ props.mainClassName ? props.mainClassName : '' } flex-col ${open ? 'lg:pl-60 xl:pl-60' : 'lg:pl-20 xl:pl-20'} h-screen relative`}>
                <TopBar isSideBarOpen={open} userEmail={props.userEmail} setOpenModal={(flag, key) => {
                    setOpenModal({flag, key});
                    if (key === modalScreensEnum[modalScreensEnum.profile]) {
                        dispatch(sideBarONOFFAction(true));
                    }                    
                }} navigate={props.navigate} mainTitle={props.mainTitle} />
                <main className={props?.isReportTab ? "py-0" : "py-6"}>
                    <div className='w-full h-16 invisible'>_</div>
                    <div className={`h-full ${props.className}`}>
                        {props.children}
                    </div>
                </main>
            </div>

            {openModal.flag &&
                <Modal open={openModal.flag && openModal.key === modalScreensEnum[modalScreensEnum.password]} setOpen={(e: boolean)=> setOpenModal({ ...openModal, flag: e })}
                        isSmall={openModal.key === modalScreensEnum[modalScreensEnum.password]}>
                    { openModal.key === modalScreensEnum[modalScreensEnum.password] ?
                        <ChangePasswordScreen setOpen={(e: boolean)=> setOpenModal({ ...openModal, flag: e })} />
                    : null }
                </Modal>
            }

            {loader && (
                <div className="loader app-preloader">
                    <img alt="wizardlogo" src={Images.logo} width={90} height={90} />
                </div>
            )}
        </React.Fragment>
    );
}

import React, {Fragment, useState} from 'react'

import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/20/solid';
import CommonMethodsUI from "Libraries/CommonMethodsUI";

interface Props {
    arr: { id: number, name: any; condition?: boolean; }[];
    placeholder: string;
    setData: (obj: {id: number; name: any; }) => void;
    styles?: string;
    isSmallIcon?: boolean;
}

const Dropdown:React.FC<Props> = ({ arr, placeholder, setData, styles, isSmallIcon }) => {
    const [active, setActive] = useState<string>('');

    return (
        <Menu as="div" className="relative inline-block text-left">
            {({open}) => (
                <>                            
                    <div>
                        <Menu.Button className={`inline-flex w-full justify-center ${styles}
                        bg-transparent py-1 ${isSmallIcon ? 'text-xs' : 'text-sm'} font-medium text-black`}>
                            <span className='text-ellipsis'>{(active && !active.includes('to me')) ? active : placeholder}</span>
                            <ChevronDownIcon className={isSmallIcon ? "h-4 w-4 ml-3": "h-5 w-5 ml-3"} aria-hidden="true" />
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment} show={open}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <MenuItems 
                            arr={arr} 
                            isSmallIcon={isSmallIcon} 
                            onClick={(e: any, item: any)=> {
                                e.stopPropagation();
                                setData(item);
                                if (![5, 7].includes(item.id)) {
                                    setActive(item.name);
                                }
                            }} 
                        />                        
                    </Transition>
                </>
            )}
        </Menu>
    )
};

export const CustomMenu = ({ children, title, value, onRemove }: { children: any | JSX.Element; title: string; value: string; onRemove: () => void; }) => {
    return (
        <Menu as="div" className="relative inline-block text-left">
            {({open}) => (
                <>
                    <div>
                        <Menu.Button className="inline-flex gap-x-1.5 mr-3 items-center justify-center px-4 rounded-full bg-primary-blue-dark hover:bg-opacity-60 transition-all duration-200 h-7 text-xs font-semibold text-white shadow-sm focus-visible:outline">
                            {value ? value : title}
                            { value ? (
                                <XMarkIcon onClick={(e) => {
                                    e.stopPropagation();
                                    onRemove();
                                }} className="-mr-1 h-7 w-4 pt-0.5" aria-hidden="true" />
                            ) : (
                                <ChevronDownIcon className="-mr-1 h-7 w-4 pt-0.5" aria-hidden="true" />
                            )}                            
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment} show={open}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        {children}
                    </Transition>
                </>
            )}
        </Menu>
    );
}

export const MenuItems = React.forwardRef((
    props: { arr: { id: number, name: any; condition?: boolean; }[]; isSmallIcon?: boolean; onClick: (e: any, item: { id: number, name: any; condition?: boolean; }) => void; }, ref) => {
    const { isSmallIcon, arr, onClick } = props;

    if (arr.length && arr[0]?.id !== undefined) {
        return (
            <Menu.Items 
                className="absolute right-0 mt-2 w-48 max-h-48 overflow-y-auto remove-scrollbar origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                style={{zIndex: 99}}
                ref={ref as any}
            >
                <div className="py-1">
                    { arr.map((item: any) => (
                        <Menu.Item key={item.id}>
                            {({ active }) => (
                                <div className={CommonMethodsUI.classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        `block px-3 py-2 ${isSmallIcon ? 'text-xs' : 'text-sm'} cursor-pointer`)}
                                    onClick={(e)=> onClick(e, item)}>
                                    {item.name}
                                </div>
                            )}
                        </Menu.Item>
                    )) }
                </div>
            </Menu.Items>
        );
    }
    
    return <Menu.Items ref={ref as any} />;
});

export default Dropdown;

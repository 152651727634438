import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import thunk from 'redux-thunk';

// rootReducer
import { RootReducer } from './Reducers';
import { IReducers } from 'Libraries/State.Interfaces';

const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2,
    whiteList: ['loginReducer']
};

// @ts-ignore
const rootReducer = combineReducers<IReducers>(RootReducer);

// @ts-ignore
const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = compose;

export const configureStore = () => createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(thunk))
);

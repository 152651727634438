import React from 'react';

import {Input} from "../Input/Input";
import {Checkbox} from "../Checkbox/Checkbox";
import {Button} from "../Button/Button";

interface Props {
    arr?: {id: number, name: any}[];
}

const CreateRecord:React.FC<Props> = ({arr}) => {

    const AddCompanyInputFields = [
        {id: 1, type: 'text', field: 'input', label: 'Name', name: 'name', placeholder: 'Please Enter Company Name' },
        {id: 2, type: 'text', field: 'input', label: 'Code', name: 'code', placeholder: 'Please Enter Company Code' },
        {id: 3, type: 'text', field: 'input', label: 'Key', name: 'key', placeholder: 'Please Enter Company Key' },
    ];

    const AddCompanyCheckFields = [
        {id: 1, type: 'checkbox', field: 'checkbox', label: 'Custom Template', name: 'custom' },
        {id: 2, type: 'checkbox', field: 'checkbox', label: 'AIR Template', name: 'air' },
        {id: 3, type: 'checkbox', field: 'checkbox', label: 'RMS Template', name: 'rms' }
    ];

    return (
        <div>
            <div className="font-bold text-2xl mb-2 text-center"> {'Add Company'} </div>


            {AddCompanyInputFields.map((company, i) => (
                 <div key={i}>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            {company.label}
                        </label>
                        <div className="mt-1">
                            <Input
                                id={company.name}
                                name={company.name}
                                type={company.type}
                                value={''}
                                onChange={(e: any)=> console.log('')}
                                autoComplete={'off'}
                                placeholder={company.placeholder}
                                className={`block w-full appearance-none rounded-md border border-gray-300 
                                        px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                            />

                            <div className="mt-1 text-sm text-red-500 h-1">
                                {/*{error.emailError && error.emailError}*/}
                            </div>
                        </div>
                </div>
            ))}

            <div className={'flex flex-row w-full mt-4'}>
                {AddCompanyCheckFields.map((acheck, i) => (
                    <Checkbox title={acheck.label} key={i} className={'w-1/2'} />
                ))}
            </div>

            <div className=" py-3 sm:flex sm:flex-row-reverse">
                <Button
                    onClick={()=> console.log('')}
                    className=" rounded-md border border-transparent bg-cc-blue-primary py-2 px-3 ml-4
                        text-md font-medium text-white shadow-sm hover:bg-cc-blue-dark focus:outline-none"
                    title={'Save'}
                />
                <Button
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => console.log('')}
                    title={'Cancel'}
                />
            </div>
        </div>
    )
};

export default CreateRecord;

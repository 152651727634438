import Methods from 'Libraries/CommonMethodsUI';
import React from 'react';

export default function TIVLegends({ data }: { data: { tiv: number; color: string; }[]; }): JSX.Element {
    return (
        <div className='flex flex-col bg-colors-warmGray-100 rounded-md p-2 max-h-56 overflow-y-auto remove-scrollbar'>
            {data.map((v, ind: number) => (
                <div key={v.tiv} className='flex flex-row items-center justify-start mb-1.5'>
                    <span className="mr-2 w-3 h-3 rounded-full" style={{ 
                        backgroundColor: v.color,
                        border: '1px solid black'
                    }}></span>
                    <span className='text-xs text-black font-medium'> {ind === 0 ? 0 : Methods.numberFormatter(Math.round(data[ind - 1].tiv))} - {Methods.numberFormatter(Math.round(v.tiv))} </span>
                </div>
            ))}
        </div>
    );
}
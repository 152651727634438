import { SpinnerLoader } from 'Components/Loader/Loader';
import React from 'react';

// contracts
interface Props {
    title: string | any;
    className: string;
    onClick?: (e?: any) => void;
    disabled?: boolean;
    id?: string;
    style?: any;
    isCrossIcon?: boolean;
    backCrossClick?: (e?: any) => void;
    item?: any;
    loading?: boolean;
}

export const Button: React.FC<Props> = ({title, loading, className, onClick, disabled, id, style, isCrossIcon, backCrossClick, item, ...rest}) => {

    return (
        <button
            type={'button'}
            className={className}
            onClick={(e) => onClick ? onClick(e) : null}
            style={style}
            disabled={disabled}
            id={id}
            {...rest}
        >
            {loading ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : title}
        </button>
    );
};

import React, { FC } from 'react';

interface ICheckbox{
    className?: string;
    title?: string;
}

export const Checkbox:FC<ICheckbox> = ({title, className} ) => {
    return(
        <div className={`${className}`}>
            <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                    <input
                        id="comments"
                        aria-describedby="comments-description"
                        name="comments"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                </div>
                <div className="ml-3 text-sm leading-6">
                    <label htmlFor="comments" className="font-medium text-gray-900">
                        {title}
                    </label>
                </div>
            </div>
        </div>
    );

};

export default function CustomCheckBox({ checked, forHead, setChecked, className }: { className?: string; checked: boolean; forHead: boolean; setChecked: (f: boolean) => void; }): JSX.Element {
    return (
        <div 
            className={`${checked ? 'bg-[#35B0E7]' : 'bg-transparent'} ${className} h-5 w-5 rounded-sm inline-flex items-center cursor-pointer justify-center border ${ forHead ? 'border-primary-grey-10' : 'border-primary-grey' }`}
            onClick={() => setChecked(!checked)}
        >
            { checked ? (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3 text-white">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                </svg>          
            ) : null }
        </div>
    );
}
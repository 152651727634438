import * as React from 'react';

import withAuthentication from "Hoc/WithAuthentication";
import { useLocation } from 'react-router-dom';

import PaginatedRecord from "Components/PaginatedRecord/PaginatedRecord";
import { Paths } from "Libraries/Route";
import MasterLayout from 'Hoc/MasterLayout';
import DocumentTitle from 'Components/DocumentTitle/DocumentTitle';
import { ISubmissionListItem } from 'Libraries/Interfaces';
import { CustomTable } from 'Components/CustomTable/CustomTable';
import { SearchFilter } from 'Components/ScreenFilters/ScreenFilters';
import Constants from 'Libraries/Constants';

const TemplateHistory = ({ navigate, user }: any) => {

    const routerState: number | null = useLocation().state;

    const [currentPage, setCurrentPage] = React.useState<number>(routerState ? routerState : 1);
    const [total, setTotal] = React.useState<number>(10);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [submissionList, setSubmissionList] = React.useState<{ copy: ISubmissionListItem[], original: ISubmissionListItem[] }>({ copy: [], original: [] });
    const [filters, setFilters] = React.useState<{ key: string; value: string; }>({ key: '', value: '' });

    React.useEffect(() => {
        setLoading(true);
    }, [currentPage]);

    return (
        <MasterLayout mainTitle='Template History' className='px-4 sm:px-6 lg:px-8' userEmail={user?.email as string} mainClassName='overflow-hidden'>
            <DocumentTitle title='Template History'>
                <SearchFilter arr={Constants.screenFilters.filter(f => [2, 4].includes(f.id))} setFilters={setFilters} onSearch={() => console.log()} />
                <div className={`flow-root mt-3 transition-all max-h-screen duration-200`}>
                    <CustomTable 
                        maxHeight="max-h-screen" loading={loading} emptyList={true} minWidth={0}
                        headers={{ data: ['Company', 'Users', 'Type', 'Created On', 'Action'], hasIcons: false }}
                    >
                        <></>
                    </CustomTable>
                </div>

                <PaginatedRecord
                    totalPages={Math.ceil(total / 10)} total={total} listLength={submissionList.original.length}
                    onPageChange={(p) => {
                        setCurrentPage(p);
                        navigate(`${Paths.dashboard}`, { state: p });
                    }} currentPage={currentPage} perPage={10}
                    onDisplayChange={(n: number) => setSubmissionList({ ...submissionList, original: submissionList.copy.slice(0, n) })}
                />
                            
            </DocumentTitle>
        </MasterLayout>
    )
};

export default withAuthentication(TemplateHistory, Paths.login, 4);

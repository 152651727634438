import * as React from 'react';

import DocumentTitle from 'Components/DocumentTitle/DocumentTitle';
import MasterLayout from 'Hoc/MasterLayout';
import withAuthentication from 'Hoc/WithAuthentication';
import { Paths } from 'Libraries/Route';
import { useLocation, useParams } from 'react-router';
import { Redirect } from 'Routes/Redirect';
import { Tabs } from 'Components/Tabs/Tabs';
import ApprovedVersions from './ApprovedVersion';
import PendingVersions from './PendingVersions';
import { useFetchCurrentSubmission } from 'Hooks/UseFetch';

const VersionList = ({ navigate, user }: any) => {

    const { search } = useLocation();
    const { controlnumber } = useParams<{ controlnumber: string; }>();
    
    const [activeTab, setActiveTab] = React.useState<number>(1);

    const { submission } = useFetchCurrentSubmission(controlnumber as string);

    React.useEffect(() => {
        localStorage.removeItem("report-filters");
    }, []);

    if (!controlnumber) {
        return ( <Redirect to={Paths.dashboard} /> );
    }

    const mainTitle: string = submission?.name_insured ? `${submission?.name_insured} (${controlnumber})` : `loading ...`;

    return (
        <MasterLayout mainTitle={mainTitle} mainClassName="overflow-hidden" navigate={() => navigate(Paths.dashboard+search)} className='px-4 sm:px-6 lg:px-8' userEmail={user?.email as string}>
            <DocumentTitle title={mainTitle}>

                <Tabs activeClassName="font-semibold text-base text-[#155D9C] px-3 mx-3 border-b-2 text-center border-[#155D9C]" inActiveClassName="font-normal text-black text-center" onChange={setActiveTab} active={activeTab} tabs={[ { id: 1, name: 'Approved Submission Versions' }, { id: 2, name: 'Pending Submission Versions' } ]} />

                { activeTab === 1 ? (
                    <ApprovedVersions controlNumber={controlnumber} navigate={navigate} user={user} search={search} />
                ) : (
                    <PendingVersions controlNumber={controlnumber} user={user} />
                )}
            </DocumentTitle>
        </MasterLayout>
    );
}

export default withAuthentication(VersionList, Paths.login, 1);
import * as React from 'react';

interface IProps {
    tabs: { id: number; name: string; }[];
    active: number;
    onChange: (id: number) => void;
    activeClassName: string;
    inActiveClassName: string;
}

interface ITabWithRemove {
    title: string;
    className?: string;
    onClose: () => void;
}

export const Tabs: React.FC<IProps> = ({ active, tabs, onChange, activeClassName, inActiveClassName }) => {
    return (
        <div className="flex flex-row items-center justify-start">
            {tabs.map((tab) => (
                <span
                    onClick={() => onChange(tab.id)} key={tab.name}
                    className={`py-1 transition-all duration-150 focus:outline-none cursor-pointer ${ active === tab.id ? `${activeClassName}` : `text-primary-dark opacity-40 ${inActiveClassName}` }`}
                >{
                    tab.name}
                </span>
            ))}
        </div>
    );
}

export const TabWithRemove: React.FC<ITabWithRemove> = ({ title, onClose, className }) => {
    return (
        <span className={`inline-flex items-center gap-x-0.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600 ${className}`}>
            {title}
            <button type="button" className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-500/20" onClick={(e) => {
                e.stopPropagation();
                onClose();
            }}>
                <span className="sr-only">Remove</span>
                <svg viewBox="0 0 14 14" className="h-3.5 w-3.5 stroke-gray-700/50 group-hover:stroke-gray-700/75">
                    <path d="M4 4l6 6m0-6l-6 6" />
                </svg>
                <span className="absolute -inset-1" />
            </button>
        </span>
    );
}
import React, {useState} from 'react';

// router
import { useLocation, useNavigate, Link } from 'react-router-dom';

// Components
import {Button} from "Components/Button/Button";
import {Input} from "Components/Input/Input";
import {SpinnerLoader} from "Components/Loader/Loader";
import CommonMethodsUI from "Libraries/CommonMethodsUI";
import {Paths} from "Libraries/Route";
import ApiRequest from "Services/ApiRequest";
import {PasswordField} from "Components/Password/Password";
import AuthWrapper from 'Hoc/AuthWrapper';
import { Images } from 'Libraries/Images';
import { Redirect } from 'Routes/Redirect';

// Interface
interface Props {}

const ForgotPasswordConfirmScreen:React.FC<Props> = () => {
    const navigate = useNavigate();
    const { search } = useLocation();
    const email: string | undefined = search ? search.split('=')[1] : undefined;
    
    const [credentials, setCredentials] = useState<{ [key: string]: string }>({ confirmPassword: '', password: '', otp: '' });
    const [error, setError] = useState<{ [key: string]: string }>({ confirmPassword: '', password: '', otp: '' });
    const [isResetLoading, setIsResetLoading] = useState<boolean>(false);
    const [resetDone, setResetDone] = useState<boolean>(false);

    async function forgotPasswordUser(): Promise<void> {
        const { ValidatePassword } = CommonMethodsUI;
        const { password, otp, confirmPassword }  = credentials;

        if(!ValidatePassword(password) || !ValidatePassword(confirmPassword)) setError({ ...error, confirmPassword: 'Please include uppercase, lowercase and number', password: 'Please include uppercase, lowercase and number' });
        else if(!otp) setError({ confirmPassword: '', password: '', otp: 'Please type OTP' });
        else if(password !== confirmPassword) setError({ confirmPassword: 'Password not matched', password: '', otp: '' });
        else {
            setIsResetLoading(true);
            setError({email: '', password: '', otp: ''});
            try {
                await ApiRequest.resetPasswordConfirmRequest({ email, password, last_otp_secret: otp });
                setIsResetLoading(false);
                setResetDone(true);
            }
            catch (e: any) {
                setIsResetLoading(false);
                setResetDone(false);
            }
        }
    };

    const fields = [
        { id: 1, type: 'text', placeholder: 'OTP', name: 'otp' },
        { id: 2, type: 'password', placeholder: 'New Password', name: 'password' },
        { id: 3, type: 'password', placeholder: 'Confirm New Password', name: 'confirmPassword' }
    ];

    if (!email) {
        return <Redirect to={Paths.forgotPassword} />
    }

    if (resetDone) {
        return (
            <AuthWrapper email={email} backForward={false}>
                <React.Fragment>
                    <img alt="tick" src={Images.tick} width="70px" height="70px" style={{ display: 'block', margin: '0 auto' }} />
                    <h6 className="font-semibold text-white text-center my-4 text-xl">Password reset successfully</h6>
                    <Button
                        onClick={() => navigate(Paths.login)} id={'login-back'} disabled={false}
                        className="flex w-full rounded-lg border border-transparent h-10 flex-col items-center
                        text-sm font-medium text-white focus:outline-none m-0 bg-primary-skyblue justify-center hover:bg-opacity-60 transition-all duration-200"
                        title="Login"
                    />
                </React.Fragment>
            </AuthWrapper>
        );
    }

    return (
        <AuthWrapper email={email} backForward={true}>
            <React.Fragment>
                <h6 className="font-semibold text-white text-center mb-1 text-xl">Reset Password</h6>
                <span className='text-center text-red-400 m-0 text-xs block mb-6'>Choose a new password for your account</span>

                { fields.map((field, index) => (
                    <div className="mb-3" key={index}>
                        { field.type === 'password' ? (
                            <PasswordField
                                id={field.name} name={field.name} value={credentials[field.name]} placeholder={field.placeholder}
                                onChange={(e: any)=> {
                                    setCredentials({ ...credentials, [field.name]: e.target.value });
                                    setError({ ...error, [field.name]: '' });
                                }} autoComplete={'off'}                              
                                className={`block w-full text-white appearance-none rounded-lg border border-white px-3 py-2 ${error.passwordError ? 'border-red-500' : 'border-white'} 
                                placeholder-white bg-transparent shadow-sm focus:border-gray-300 focus:outline-none sm:text-sm h-10 input-modify`}
                            />
                        ) : (
                            <Input
                                id={field.name} name={field.name} value={credentials[field.name]} placeholder={field.placeholder} autoComplete={'off'}
                                onChange={(e: any)=> {
                                    setCredentials({ ...credentials, [field.name]: e.target.value });
                                    setError({ ...error, [field.name]: '' });
                                }} type={field.type}
                                className={`block w-full text-white appearance-none rounded-lg border border-white px-3 py-2 ${error.emailError ? 'border-red-500' : 'border-white'} 
                                placeholder-white bg-transparent shadow-sm focus:border-gray-300 focus:outline-none sm:text-sm h-10 input-modify`}
                            />
                        )}
                        { error[field.name] && (
                            <div className="mt-1 text-xs text-red-500">
                                {error[field.name]}
                            </div>
                        )}
                    </div>
                )) }          
                <br />
                <Button
                    onClick={() => forgotPasswordUser()} id={'signup-submit-btn'} disabled={isResetLoading}
                    className="flex w-full rounded-lg border border-transparent h-10 flex-col items-center mb-4
                    text-sm font-medium text-white focus:outline-none m-0 bg-primary-skyblue justify-center hover:bg-opacity-60 transition-all duration-200"
                    title={isResetLoading ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 'Reset'}                    
                />

                <Link className='text-white border border-white flex flex-col justify-center items-center h-10 rounded-lg hover:opacity-60 transition-all duration-200' to={Paths.login}>Back to Login</Link>

                <div className='flex items-center justify-center text-white text-sm mt-3'>
                    <span className='text-sm'>Already have not account?</span>
                    <Link className='text-white underline ml-2 hover:opacity-60 transition-all duration-200' to={Paths.signup}>Signup</Link>
                </div>
            </React.Fragment>
        </AuthWrapper>
    )
};

export default ForgotPasswordConfirmScreen;

import React from "react";

import Constants from "Libraries/Constants";
import { NavLink } from 'react-router-dom';
import { Images } from "Libraries/Images";
import Profile from 'Screens/Profile/Profile';
import { useSelector } from "react-redux";
import { IReducers } from "Libraries/State.Interfaces";

export default function SidePanel({ isSideBarOpen, isProfile, setOpen }: { isSideBarOpen: boolean; isProfile: boolean; setOpen: (f: boolean) => void; }): JSX.Element {

    const { user } = useSelector((state: IReducers) => state.loginReducer);

    const [iconState, setIconState] = React.useState(true);

    React.useEffect(() => {
        setTimeout(() => {
            setIconState(isSideBarOpen);
        }, 200);
    }, [isSideBarOpen]);

    return(
        <div>
            {/* Static sidebar for desktop */}
            <div className={`hidden lg:fixed lg:inset-y-0 z-10 lg:flex ${isSideBarOpen ? 'lg:w-60 xl:w-60' : 'lg:w-20 xl:w-20'} lg:flex-col bg-primary-dark transition-all duration-200`}>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-primary-grey-20 pb-4">
                    <div className="flex h-16 shrink-0 items-center justify-center bg-primary-bl-3per">
                        <img
                            className={`cursor-pointer ${!iconState && 'hidden'}`} width={"170px"} src={Images.completeLogo }
                            alt="WA Logo" onClick={() => setOpen(!isSideBarOpen)}
                        />
                        <img
                            className={`cursor-pointer ${iconState && 'hidden'}`} width={"50px"} src={Images.logo} alt="WA Logo" onClick={() => setOpen(!isSideBarOpen)}
                        />                         
                    </div>
                    <div className="mt-5 flex flex-1 flex-col">
                        <nav className="flex-1 space-y-1 pb-4">
                            { isProfile ? <Profile /> : Constants.navigation(user?.company_id?.company_name === 'Wizard Analytics').filter(f => f.condition).map((item) => (
                                <NavLink
                                    key={item.name} to={item?.path}
                                    className={({ isActive }) =>
                                        `group flex items-center ${isSideBarOpen ? 'pl-4' : 'justify-center'} transition-all duration-200 text-sm cursor-pointer hover:text-[#BFBFBE] hover:bg-transparent h-12 ${isActive ? 'text-[#484848] bg-[#BFBFBE] font-medium' : 'text-[#484848] bg-transparent'}`
                                    }
                                >
                                    { isSideBarOpen ? <>
                                        <item.icon className="mr-4 h-5 w-5 flex-shrink-0" aria-hidden="true" />
                                        {item.name}
                                    </> : (
                                        <item.icon className="h-6 w-6 flex-shrink-0" aria-hidden="true" />
                                    )}                                    
                                </NavLink>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )
};

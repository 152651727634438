import * as React from 'react';

import withAuthentication from "Hoc/WithAuthentication";
import { useLocation } from 'react-router-dom';

import PaginatedRecord from "Components/PaginatedRecord/PaginatedRecord";
import { Paths } from "Libraries/Route";
import MasterLayout from 'Hoc/MasterLayout';
import DocumentTitle from 'Components/DocumentTitle/DocumentTitle';
import { IReportedConstruction, IResponse } from 'Libraries/Interfaces';
import { CustomTable } from 'Components/CustomTable/CustomTable';
import { SearchFilter } from 'Components/ScreenFilters/ScreenFilters';
import ApiRequest from 'Services/ApiRequest';
import Methods from 'Libraries/CommonMethodsUI';
import { DeleteOrAddPremiumModal } from 'Components/Modal/Modal';
import { Tooltip } from 'react-tooltip';
import Constants from 'Libraries/Constants';

const ReportedConstruction = ({ navigate, user }: any) => {

    const routerState: number | null = useLocation().state;

    const [currentPage, setCurrentPage] = React.useState<number>(routerState ? routerState : 1);
    const [total, setTotal] = React.useState<number>(10);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [list, setList] = React.useState<{ copy: IReportedConstruction[], original: IReportedConstruction[] }>({ copy: [], original: [] });
    const [filters, setFilters] = React.useState<{ key: string; value: string; }>({ key: '', value: '' });

    const fetchInitial = React.useCallback(async () => {
        setLoading(true);
        let params: string = `?page=${currentPage}&page_size=10`;

        if (filters.key && filters.value) {
            params = params + `&${filters.key}=${filters.value}`;
        }

        try {
            const res = await ApiRequest.getReportedConstructions(params);
            setTotal(res.data.count);
            setList({ copy: res.data.results, original: res.data.results });
            setLoading(false);
        }
        catch (err) {
            setTotal(0);
            setList({ copy: [], original: [] });
            setLoading(false);
        }
    }, [currentPage, filters]);

    React.useEffect(() => {
        if (filters.value) {
            setCurrentPage(1);
        }
    }, [filters.value]);

    React.useEffect(() => {
        fetchInitial();
    }, [currentPage, fetchInitial]);

    return (
        <MasterLayout mainTitle='Reported Construction' className='px-4 sm:px-6 lg:px-8' userEmail={user?.email as string} mainClassName='overflow-hidden'>
            <DocumentTitle title='Reported Construction'>
                <SearchFilter arr={Constants.screenFilters.slice(0, 3)} setFilters={setFilters} onSearch={() => {
                    if (currentPage === 1) {
                        fetchInitial();
                    } else {
                        setCurrentPage(1);
                    }
                }} />
                <div className={`flow-root mt-3 transition-all max-h-screen duration-200`}>
                    <CustomTable 
                        maxHeight="max-h-screen" loading={loading} emptyList={list.original.length === 0} minWidth={0}
                        headers={{ data: ['Company', 'Construction', 'Name', 'Type', 'Code', 'Created On', 'Created By', 'Mapping', 'Match %', 'Action'], hasIcons: false }}
                    >
                        { list.original.map((item: IReportedConstruction) => (
                            <tr className={`hover:bg-[#FFEB8C] transition-all duration-200 border-b border-primary-grey cursor-pointer`}
                                key={item.id}
                            >
                                <td className="whitespace-nowrap py-3 px-3 text-xs text-black">{item.company_name}</td>
                                <td className="whitespace-nowrap py-3 px-3 text-xs text-black">
                                    { item.construction?.length > 20 ? (
                                        <div 
                                            data-tooltip-id="construction-item"
                                            data-tooltip-content={item.construction}
                                            data-tooltip-place="bottom"
                                            className={item.construction?.length > 20 ? 'truncate w-32' : ''}
                                        >
                                            {item.construction}
                                        </div>
                                    ) : item.construction }
                                </td>
                                <td className="whitespace-nowrap py-3 px-3 text-xs text-black">
                                    { item.construction_code_id.construction?.length > 20 ? (
                                        <div 
                                            data-tooltip-id="construction-code-item"
                                            data-tooltip-content={item.construction_code_id.construction}
                                            data-tooltip-place="bottom"
                                            className={item.construction_code_id.construction?.length > 20 ? 'truncate w-40' : ''}
                                        >
                                            {item.construction_code_id.construction}
                                        </div>
                                    ) : item.construction_code_id.construction }
                                </td>
                                <td className="whitespace-nowrap py-3 px-3 text-xs text-black"> {item.construction_code_id.code_type} </td>
                                <td className="whitespace-nowrap py-3 px-3 text-xs text-black">{item.construction_code_id.code}</td>
                                <td className="whitespace-nowrap py-3 px-3 text-xs text-primary-grey-10">{Methods.convertUTCtoLocalDate(item.created_on).toString()}</td>
                                <td className="whitespace-nowrap py-3 px-3 text-xs text-primary-grey-10">{item.created_by}</td>
                                <td className="whitespace-nowrap py-3 px-3 text-xs text-black"> {item.count} </td>
                                <td className="whitespace-nowrap py-3 px-3 text-xs text-black min-w-[150px]"> {item.percentage}% </td>
                                <td className="whitespace-nowrap py-3 px-3 text-xs text-black">
                                    <DeleteOrAddPremiumModal status={{ id: 0, value: '' }} onConfirm={() => console.log()}>
                                        <span className='px-4 py-1 rounded-full bg-primary-red hover:bg-opacity-60 transition-all duration-200 text-sm text-white'>Delete</span>
                                    </DeleteOrAddPremiumModal>
                                </td>
                                <Tooltip id="construction-item" arrowColor='#2D2D2D' style={{ color: '#f1f1f1' }} />
                                <Tooltip id="construction-code-item" arrowColor='#2D2D2D' style={{ color: '#f1f1f1' }} />
                            </tr>
                        )) }
                    </CustomTable>
                </div>

                <PaginatedRecord
                    totalPages={Math.ceil(total / 10)} total={total} listLength={list.original.length}
                    onPageChange={(p) => setCurrentPage(p)} currentPage={currentPage} perPage={10}
                    onDisplayChange={(n: number) => setList({ ...list, original: list.copy.slice(0, n) })}
                    maxInput={10}
                />
                            
            </DocumentTitle>
        </MasterLayout>
    )
};

export default withAuthentication(ReportedConstruction, Paths.login, 5);

import React from 'react';
import {FaSpinner} from "react-icons/fa";


export interface Props {
    enhance: string;
    adjustment: boolean;
    style?: any;
}

export const SpinnerLoader:React.FC<Props> = ({ enhance, adjustment, style }) => {
    return (
        <div className={`w-full flex flex-row ${adjustment && 'justify-center items-center'}`}>

            <FaSpinner className={`animate-spin ${enhance}`}
                       style={style} />
        </div>
    );
};

import { APP_LOADER, IS_LOGIN, IS_LOGOUT, SET_PASSWORD_RULES, SET_USER_DETAILS, SIDEBAR_ON_OFF } from "Redux/Types";
import { IAction } from "Libraries/State.Interfaces";
import { IUserProfile } from "Libraries/Interfaces";

export const isLoginAction = (data: IUserProfile): IAction => ({
    type: IS_LOGIN,
    payload: data
});

export const isLogoutAction = (): IAction => ({
    type: IS_LOGOUT,
    payload: null
});

export const isSetUserDetailsAction = (u: IUserProfile): IAction => ({
    type: SET_USER_DETAILS,
    payload: u
});

export const sideBarONOFFAction = (g: boolean): IAction => ({
    type: SIDEBAR_ON_OFF,
    payload: g
});

export const setPasswordRulesAction = (g: string[]): IAction => ({
    type: SET_PASSWORD_RULES,
    payload: g
});

export const setAppLoaderAction = (flag: boolean): IAction => ({
    type: APP_LOADER,
    payload: flag
});

import * as React from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';
import Methods from 'Libraries/CommonMethodsUI';
import moment from "moment";
import { MdReply } from 'react-icons/md';
import { IEmailReply, IExposureFile } from 'Libraries/Interfaces';


export function File({ iconSrc, src, name }: { iconSrc: string; src: string; name: string; }): JSX.Element {
    return (
        <div className="flex flex-row items-center justify-between py-3 px-3">
            <div className='items-center inline-flex'>
                <img alt={`${name}-icon`} width="20px" height="26px" src={iconSrc} className="mr-4" />
                <span className='text-xs font-normal text-primary-grey-10'>{name}</span>
            </div>
            <div onClick={() => window.open(src, '_blank')}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer text-primary-grey-10 transition-all duration-200 ease-in-out hover:text-black">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75l3 3m0 0l3-3m-3 3v-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </div>
        </div>
    );
}

export function Attachment({ onClose, size, name, url, systemFileName }: { onClose: (() => void) | undefined; name: string; size?: number; url?: string; systemFileName: string; }): JSX.Element {
    return (
        <div className='text-sm p-2 bg-[#EBEBEB] border border-[#DBDBDB] my-1 bg-opacity-60 rounded-sm inline-flex items-center mr-3 min-w-fit'>
            <img alt={`${name}-icon`} width="20px" height="26px" src={Methods.getFileIcon(systemFileName)} className="mr-4" />
            <span className="font-medium mr-2 text-black underline">{name}</span>
            { size && (
                <span className="font-medium text-black pr-3">({Methods.formatBytes(size)})</span>
            )}
            { onClose && (
                <button
                    type="button" title="Close"
                    className="relative text-gray-400 ml-auto"
                    onClick={() => onClose()}
                >
                    <span className="absolute -inset-2.5" />
                    <XMarkIcon className="h-4 w-4 font-semibold transition-all duration-200 ease-in-out hover:text-black" aria-hidden="true" color='#9C9C9C' />
                </button>
            )}
            { url && (
                <a href={url} className='ml-auto' target="_blank" rel="noreferrer" title={name}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer text-primary-grey-10 transition-all duration-200 ease-in-out hover:text-black">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75l3 3m0 0l3-3m-3 3v-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </a>
            )}
        </div>
    );
}

export function EmailItem({ sender, created_on, cc_list, email_body, attachments, isReply }: { 
    sender: string; created_on: string; cc_list: string[]; email_body: string; attachments: IExposureFile[];
    isReply?: boolean;
}): JSX.Element {
    return (
        <div className="flex flex-col">
            <div className='flex flex-row items-center justify-start px-6'>
                <div className="grid grid-cols-1 w-full">
                    <div className="relative flex items-center space-x-4 rounded-lg bg-white">
                        <div className="flex-shrink-0">
                            <img className="h-10 w-10 rounded-full" src="https://png.pngtree.com/png-clipart/20210604/ourmid/pngtree-gray-male-avatar-png-image_3416112.jpg" alt="" />
                        </div>
                        <div className="min-w-full flex-1 focus:outline-none pr-12">
                            <span className="absolute inset-0" aria-hidden="true"></span>
                            <div className="flex items-center justify-between">
                                <p className="text-sm font-medium text-black capitalize">
                                    {sender?.split('@')[0] ?? ''} <span className="text-xs text-[#6C757D] m-0 p-0">{`<${sender}>`}</span>
                                </p>
                                <span className="text-[9px] font-medium w-auto text-[#6C757D] p-0">{moment(created_on as string).format('MMM D, YYYY, h:mm A')} ({moment(created_on as string).fromNow()})</span>
                            </div>                                        
                            <div className="flex items-center justify-between">
                                <p className="text-xs text-[#6C757D]"> 
                                    { cc_list.length > 0 ? <>
                                        <b>cc: </b> {`<${cc_list?.toString().split(',').join(', ')}>`}
                                    </> : "" }                                                 
                                </p>
                                { isReply && (
                                    <button onClick={() => Methods.scrollToSection("exposure-modal-textfield")} className='inline-flex items-center text-[#3750B5] font-bold hover:opacity-60 transition-all duration-200'>
                                        <MdReply />
                                        <span className='text-xs'>Reply</span>
                                    </button>
                                )}
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
            <div className={`text-sm text-black block px-6 py-4 ${!email_body ? 'text-center' : 'text-justify'}`} dangerouslySetInnerHTML={{ __html: email_body?.split('“').join('"').split('”').join('"').split('"').join("'") }}></div>
            { attachments.length > 0 && (
                <>
                    <h6 className='text-xs text-black font-medium block pl-6'>Attachments</h6>
                    <div className="flex flex-row items-center justify-start w-full flex-wrap pt-1 px-6">
                        { attachments.map((item: IExposureFile, index: number) => (
                            <Attachment 
                                key={index} name={item.original_file_name} size={undefined}
                                onClose={undefined} url={item.file} systemFileName={item.system_file_name}
                            />
                        )) }
                    </div>
                    <br />
                </>
            )} 
        </div>
    );
}

export function EmailReplies({ replies }: { replies: IEmailReply[]; }): JSX.Element | null {

    const [expand, setExpand] = React.useState<number | null>(null);

    if (replies.length === 0){
        return null;
    }

    return (
        <React.Fragment>
            {replies.map((reply: IEmailReply, ind: number) => (
                <div className="flex flex-col mb-3" key={ind}>
                    <div className={`flex flex-row items-center justify-start cursor-pointer px-6 ${expand === ind ? '' : 'py-3 border-b border-primary-grey'}`} onClick={() => setExpand(expand === ind ? null : ind)}>
                        <div className="grid grid-cols-1 w-full">
                            <div className="relative flex items-center space-x-4 rounded-lg bg-white">
                                <div className="flex-shrink-0">
                                    <img className="h-10 w-10 rounded-full" src="https://png.pngtree.com/png-clipart/20210604/ourmid/pngtree-gray-male-avatar-png-image_3416112.jpg" alt="" />
                                </div>
                                <div className="min-w-full flex-1 focus:outline-none pr-12">
                                    <span className="absolute inset-0" aria-hidden="true"></span>
                                    <div className="flex items-center justify-between">
                                        <p className="text-sm font-medium text-black capitalize">
                                            {reply.created_by?.toString()?.split('@')[0] ?? ''} <span className="text-xs text-[#6C757D] m-0 p-0">{`<${reply.created_by?.toString()}>`}</span>
                                        </p>
                                        <span className="text-[9px] font-medium w-auto text-[#6C757D] p-0">{moment(reply.created_on as string).format('MMM D, YYYY, h:mm A')} ({moment(reply.created_on as string).fromNow()})</span>
                                    </div>                                        
                                    <div className="flex items-center justify-between text-xs text-black text-left">
                                        { expand !== ind ? (
                                            <div className="truncate" dangerouslySetInnerHTML={{ __html: reply?.body?.split('“').join('"').split('”').join('"').split('"').join("'") }}></div>
                                        ) : (
                                            <p className="truncate">
                                                { reply?.subject }
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    { expand === ind && (
                        <>
                            <div className={`text-sm text-black block px-6 py-4 ${!reply?.body ? 'text-center' : 'text-justify'}`} dangerouslySetInnerHTML={{ __html: reply?.body?.split('“').join('"').split('”').join('"').split('"').join("'") }}></div>
                            <EmailAttachments attachments={reply.attachments.map((item) => {
                                return {
                                    url: process.env.REACT_APP_API_KEY + `/media/${item}`,
                                    name: item.split('/')[item.split('/').length - 1],
                                    systemFileName: ''
                                }
                            })} />                            
                        </>
                    )}                
                </div>
            ))}
        </React.Fragment>
    );
}

export function EmailAttachments({ attachments }: any): JSX.Element | null {

    if (attachments.length === 0) {
        return null;
    }

    return (
        <>
            <h6 className='text-xs text-black font-medium block pl-6'>Attachments</h6>
            <div className="flex flex-row items-center justify-start w-full flex-wrap pt-1 px-6">
                { attachments.map((item: any, index: number) => (
                    <Attachment 
                        key={index} name={item.name} size={undefined}
                        onClose={undefined} url={item.url}
                        systemFileName={item.systemFileName}
                    />
                )) }
            </div>
            <br />
        </>
    );
}
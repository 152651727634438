import React, {useState} from 'react';

// router
import { useNavigate, Link } from 'react-router-dom';

// Components
import {Button} from "Components/Button/Button";
import {Input} from "Components/Input/Input";
// import CommonMethodsUI from "Libraries/CommonMethodsUI";
import {Paths} from "Libraries/Route";

// Icons
import ApiRequest from "Services/ApiRequest";
import {SpinnerLoader} from "Components/Loader/Loader";
import {PasswordField} from "Components/Password/Password";
import AuthWrapper from 'Hoc/AuthWrapper';
import { toast } from 'react-toastify';

// Interafce
interface Props {}

const SignUpScreen:React.FC<Props> = (props) => {
    const navigate = useNavigate();
    // const {ValidateEmail, ValidatePassword} = CommonMethodsUI;

    const [signupCredentials, setSignupCredentials] = useState<{ [key: string]: string }>({ firstname: '', lastname: '', companyKey: '', email: '', password: '', confirmPassword: '' });
    const [error, setError] = useState<{ [key: string]: string }>({ firstname: '', lastname: '', companyKey: '', email: '', password: '', confirmPassword: '' });
    const [isSignupLoading, setIsSignupLoading] = useState<boolean>(false);

    async function signUpUser(): Promise<void> {
        const { email, password, firstname, lastname, confirmPassword, companyKey }  = signupCredentials;

        if(!firstname) setError({ ...error, firstname: 'Please Enter First Name' });
        else if(!lastname) setError({ ...error, lastname: 'Please Enter Last Name', firstname: '' });
        else if(!companyKey) setError({ ...error, companyKey: 'Please Enter Company Key', firstname: '', lastname: '' });
        // else if(!ValidateEmail(email)) setError({ ...error, email: 'Please Enter Valid Email', firstname: '', lastname: '' });
        // else if(!ValidatePassword(password)) setError({ ...error, email: '', password: 'Please include uppercase, lowercase, special character', firstname: '', lastname: '' });
        else if(password !== confirmPassword) setError({ confirmPassword: 'Password not matched', email: '', password: '', firstname: '', lastname: '',companyKey: '' });
        else{
            setIsSignupLoading(true);
            setError({ firstnameError: '', lastnameError: '', emailError: '', passwordError: '', confirmPasswordError: '',companyKeyError: '' });
            // "WIZSTAGE" company Key

            try {
                await ApiRequest.signUpRequest({email, password, first_name: firstname, last_name: lastname, company_key: companyKey});
                setSignupCredentials({ firstname: '', lastname: '', email: '', password: '', confirmPassword: '',companyKey: '' });
                toast.success('Account Created Successfully.'); 
                setIsSignupLoading(false);
                setTimeout(() => {
                    navigate(Paths.login);
                }, 1000);
            }
            catch(e: any) {
                Object.keys(e.response.data).forEach((k: string, ind: number) => {
                    toast.error(`${k}: ${(Object.values(e.response.data) as [any[]])[ind].flat(1)}`);                    
                });
                setIsSignupLoading(false);
            }
        }
    };

    const fields = [
        { id: 1, type: 'text', placeholder: 'First Name', name: 'firstname' },
        { id: 2, type: 'text', placeholder: 'Last Name', name: 'lastname' },
        { id: 3, type: 'text', placeholder: 'Company Code', name: 'companyKey' },
        { id: 4, type: 'email', placeholder: 'Email', name: 'email' },
        { id: 5, type: 'password', placeholder: 'Password', name: 'password' },
        { id: 6, type: 'password', placeholder: 'Confirm Password', name: 'confirmPassword' }
    ];

    return (
        <AuthWrapper email={undefined} backForward={true}>
            <React.Fragment>
                <h6 className="font-semibold text-white text-center mb-6 text-xl">Sign Up</h6>

                { fields.map((field, index) => (
                    <div className="mb-3" key={index}>
                        { field.type === 'password' ? (
                            <PasswordField
                                id={field.name} name={field.name} value={signupCredentials[field.name]} placeholder={field.placeholder}
                                onChange={(e: any)=> {
                                    setSignupCredentials({ ...signupCredentials, [field.name]: e.target.value });
                                    setError({ ...error, [field.name]: '' });
                                }} autoComplete={'off'}                              
                                className={`block w-full caret-white text-white appearance-none rounded-lg border border-white px-3 py-2 ${error.passwordError ? 'border-red-500' : 'border-white'} 
                                placeholder-white bg-transparent shadow-sm focus:border-gray-300 focus:outline-none sm:text-sm h-10 input-modify`}
                            />
                        ) : (
                            <Input
                                id={field.name} name={field.name} value={signupCredentials[field.name]} placeholder={field.placeholder} autoComplete={'off'}
                                onChange={(e: any)=> {
                                    setSignupCredentials({ ...signupCredentials, [field.name]: e.target.value });
                                    setError({ ...error, [field.name]: '' });
                                }} type={field.type}
                                className={`block w-full caret-white text-white appearance-none rounded-lg border border-white px-3 py-2 ${error.emailError ? 'border-red-500' : 'border-white'} 
                                placeholder-white bg-transparent shadow-sm focus:border-gray-300 focus:outline-none sm:text-sm h-10 input-modify`}
                            />
                        )}
                        { error[field.name] && (
                            <div className="my-1 text-xs text-red-500">
                                {error[field.name]}
                            </div>
                        )}
                    </div>
                )) }          
                <br />

                <Button
                    onClick={() => signUpUser()} id={'signup-submit-btn'} disabled={isSignupLoading}
                    className="flex w-full rounded-lg border border-transparent h-10 flex-col items-center
                    text-sm font-medium text-white focus:outline-none m-0 bg-primary-skyblue justify-center hover:bg-opacity-60 transition-all duration-200"
                    title={isSignupLoading ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 'Create Account'}                    
                />

                <div className='flex items-center justify-center text-white text-sm mt-6'>
                    <span>Already have an account?</span>
                    <Link className="ml-1 underline hover:opacity-60 transition-all duration-200" to={Paths.login}>Sign In</Link>
                </div>

            </React.Fragment>
        </AuthWrapper>       
    )
};

export default SignUpScreen;

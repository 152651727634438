import * as React from 'react';

import { RightSidePanel } from 'Components/RightSidePanel/RightSidePanel';
import PaginatedRecord from 'Components/PaginatedRecord/PaginatedRecord';
import ExposuresModal from 'Components/ExposuresModal/ExposuresModal';
import { MdDelete, MdEmail } from 'react-icons/md';
import { IoDocuments } from 'react-icons/io5';
import ApiRequest from 'Services/ApiRequest';
import { IUserProfile, IVersionListItem, IVersionStatus } from 'Libraries/Interfaces';
import Methods from 'Libraries/CommonMethodsUI';
import Dropdown from 'Components/Dropdown/Dropdown';
import { toast } from 'react-toastify';
import { CustomTable } from 'Components/CustomTable/CustomTable';
import CustomCheckBox from 'Components/Checkbox/Checkbox';
import { Tooltip } from 'react-tooltip';
import Constants from 'Libraries/Constants';
import { ConfirmModal, DeleteOrAddPremiumModal } from 'Components/Modal/Modal';
import { RolesEnum } from 'Libraries/Enums';
import { NavigateFunction } from 'react-router';
import { NavLink } from 'react-router-dom';

interface IProps {
    user: IUserProfile;
    controlNumber: string;
    search: string;
    navigate: NavigateFunction;
}

const ApprovedVersions: React.FC<IProps> = ({ controlNumber, navigate, user, search }) => {

    const [checked, setChecked] = React.useState<string[]>([]);
    const [selectedVersion, setSelectedVersion] = React.useState<{ item: IVersionListItem | undefined; flag: boolean; }>({ item: undefined, flag: false });
    const [exposurePanel, setExposurePanel] = React.useState<boolean>(false);
    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [total, setTotal] = React.useState<number>(1);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [showPremiumDialog, setShowPremiumDialog] = React.useState<{ flag: boolean; args: { status_: number, version: number }; value: string; }>({
        flag: false, args: { status_: 0, version: 0 }, value: ''
    });
    const [versionList, setVersionList] = React.useState<{ copy: IVersionListItem[], original: IVersionListItem[] }>({ copy: [], original: [] });

    const getApprovedVersions = React.useCallback(async (f: boolean) => {
        if (!controlNumber) {
            setLoading(false);
            setChecked([]);
            return;
        }

        setLoading(f);
        const params: string = `?control_number=${controlNumber}&page_size=10&page=${currentPage}`;
        try {
            const res = await ApiRequest.getSubmissionVersionsRequest(params);
            setTotal(res.data.count);
            setVersionList({ copy: res.data.results, original: res.data.results });
        }
        catch(e) {}

        setLoading(false);
        setChecked([]);
    }, [controlNumber, currentPage]);

    React.useEffect(() => {
        getApprovedVersions(true);
    }, [getApprovedVersions]);

    async function versionAssignToMe(id: number): Promise<void> {
        try {
            await ApiRequest.submissionAssignRequest({ submission_version_id: id });
            toast.success('Version has been assigned successfully.');
            getApprovedVersions(false);
        }
        catch (err) {}
    };

    const duplicateSelectedVersion = React.useCallback(async (): Promise<void> => {
        try {
            await ApiRequest.dublicateVersion({ control_number: controlNumber as string, version: parseInt(checked[0]) });
            toast.success('Version has been duplicated successfully.');
            getApprovedVersions(false);
            setChecked([]);
        }
        catch (err) {}
    }, [controlNumber, checked, getApprovedVersions]);

    const archiveSelectedVersions = React.useCallback(async (): Promise<void> => {
        try {
            await ApiRequest.archiveVersion({ control_number: controlNumber as string, versions: checked.map(v => parseInt(v)) });
            toast.success('Versions has been archived successfully.');
            getApprovedVersions(false);
            setChecked([]);
        }
        catch (err) {}
    }, [controlNumber, checked, getApprovedVersions]);

    const onPerformStatusUpdation = React.useCallback(async (status_: number, version: number, showToaster: boolean, premium?: number | null): Promise<void> => {
        const args: IVersionStatus = { control_number: controlNumber as string, status_, version };
        if (premium) {
            args['premium'] = premium;
        }
        
        try {
            await ApiRequest.updateStatus(args);
            if (showToaster) {
                toast.success('Status has been updated successfully.');
            }
            getApprovedVersions(false);
            setChecked([]);
        }
        catch (err) {
            toast.error('Something went wrong!');
        }
    }, [controlNumber, getApprovedVersions]);

    return (
        <React.Fragment>
            <div className="h-7 mt-2">
                <div className={`items-center justify-start ml-2 ${ checked.length > 0 ? 'flex' : 'hidden' }`}>
                    <ConfirmModal isDisabledConfirm={false} onConfirm={() => archiveSelectedVersions()}>
                        <MdDelete className='cursor-pointer transition-all duration-200 ease-in-out hover:text-black text-2xl text-primary-grey-10' />
                    </ConfirmModal>
                    <IoDocuments onClick={() => duplicateSelectedVersion()} className='cursor-pointer transition-all duration-200 mx-3 ease-in-out hover:text-black text-2xl text-primary-grey-10' />
                    <MdEmail onClick={() => selectedVersion?.item && navigate(`${selectedVersion?.item?.version}/email${search}`)} className='cursor-pointer transition-all duration-200 ease-in-out hover:text-black text-2xl text-primary-grey-10 hidden' />
                </div>
            </div>
            <div className={`flow-root transition-all max-h-screen duration-200 ${selectedVersion.flag ? 'pr-72' : ''}`}>
                <CustomTable 
                    maxHeight="max-h-screen" loading={loading} emptyList={versionList.original.length === 0}
                    headers={{ data: [], hasIcons: false, modified: (
                        <tr className='border-b-2 border-primary-grey'>
                            <th scope="col" className="pt-1 text-left text-sm font-bold text-[#343A40] pl-3">
                                <CustomCheckBox checked={checked.length === versionList.original.length && versionList.original.length > 0} setChecked={(f: boolean) => {
                                    if (user.role === RolesEnum[RolesEnum.CAT_MODELER]) {
                                        setChecked(versionList.original.map((v) => v.version));
                                    }
                                    else {
                                        // toast.warning("Permission Denied.");
                                    }
                                }} forHead={true} />
                            </th>
                            { ['Version', 'Total TIV', '# of Locs', 'Premium', 'Status', 'Assigned to', 'Processed In', 'Attachments'].map((head: string, index) => (
                                <th key={index} scope="col" className="min-w-[120px] py-4 pl-4 pr-3 text-left text-sm font-bold text-[#343A40] sm:pl-0">
                                    {head}
                                </th>
                            )) }
                        </tr>
                    ) }}
                >
                    {versionList.original.map((item: IVersionListItem) => (
                        <tr key={item.id} onClick={(e) => {
                            e.stopPropagation();
                            navigate(item?.dashboard ? `${item.version}/report/systematic${search}` : `${item.version}/report${search}`);
                        }} className={`hover:bg-[#EAEAEA] rounded-sm ${ selectedVersion?.item?.id === item.id ? 'bg-[#FFEB8C]' : 'bg-transparent' } transition-all cursor-pointer duration-200 border-b border-primary-grey`}>
                            <td className="whitespace-nowrap pt-2 px-3 w-14" onClick={(e) => e.stopPropagation()}>
                                <CustomCheckBox checked={checked.includes(item.version)} setChecked={(f: boolean) => {
                                    if (user.role === RolesEnum[RolesEnum.CAT_MODELER]) {
                                        setChecked(checked.includes(item.version) ? checked.filter((f) => f !== item.version) : [ ...checked, item.version ]);
                                        setSelectedVersion({ flag: false, item });
                                    }
                                    else {
                                        // toast.warning("Permission Denied.");
                                    }
                                }} forHead={false} />
                            </td>
                            <td className={`whitespace-nowrap py-4 w-[${100/8}%] pr-3`}>
                                <span
                                    className="underline text-xs text-[#155D9C] cursor-pointer font-semibold" 
                                    onClick={(e) => {
                                        e.stopPropagation(); 
                                        setSelectedVersion({ item, flag: true });
                                    }}
                                >{item.version}.0</span>
                            </td>
                            <td className={`whitespace-nowrap w-[${100/6}%] py-4 pr-3 text-xs ${item.tiv ? 'text-[#343A40]' : 'text-[#A4A4A4]'} font-medium`}>{item.tiv ? `$ ${Math.round(item.tiv)?.toLocaleString("en-US")}` : 'N/A'}</td>
                            <td className={`whitespace-nowrap py-4 w-[${100/8}%] pr-3 text-xs ${item.no_of_loc ? 'text-[#343A40]' : 'text-[#A4A4A4]'}`}>{item.no_of_loc ? item.no_of_loc : 'N/A'}</td>
                            <td className={`whitespace-nowrap py-4 pr-3 text-xs w-[${100/8}%] ${item.premium ? 'text-[#343A40]' : 'text-[#A4A4A4]'}`}>{item.premium ? `$ ${item.premium?.toLocaleString("en-US")}` : 'N/A'}</td>
                            <td className={`whitespace-nowrap py-4 pr-3 text-xs w-[${100/8}%]`}>
                                { (Constants.getStatusId(item.status) < 4 || Constants.getStatusId(item.status) === 7 || (user.role === RolesEnum[RolesEnum.CAT_MODELER] && Constants.getStatusId(item.status) > 4)) ? (
                                    <span className="block text-[#343A40]"> {(item.status && item.status !== "0") ? item.status : 'N/A'} </span>
                                ) : (
                                    <span className='text-black mb-2 block font-medium' onClick={(e) => e.stopPropagation()}>
                                        <Dropdown
                                            isSmallIcon arr={Constants.submissionsStatuses(item.status).slice(4).filter(f => f.condition)} 
                                            placeholder={(item.status && item.status !== "0") ? item.status : 'N/A'} styles={`pr-0 text-xs`} 
                                            setData={(e: any) => {
                                                console.log(e);
                                                setShowPremiumDialog({
                                                    flag: true, args: { status_: e.id, version: parseInt(item.version) }, value: e?.name
                                                });
                                            }}
                                        />
                                    </span>
                                )}
                                <span className="text-primary-grey-10 block">{Methods.convertUTCtoLocalDate(item.updated_on)}</span>
                            </td>
                            <td className={`whitespace-nowrap py-4 pr-3 w-[${100/8}%] text-xs ${item.assigned_to ? 'text-[#343A40]' : 'text-[#A4A4A4]'} font-medium`} onClick={(e) => e.stopPropagation()}>
                                <div data-tooltip-id="assigned-to" data-tooltip-content={item.assigned_to ? item.assigned_to.toString() : ''} data-tooltip-place="bottom">
                                    <Dropdown 
                                        isSmallIcon arr={user.role === RolesEnum[RolesEnum.CAT_MODELER] ? [{ id: 1, name: 'Assign to me' }] : []}
                                        placeholder={item.assigned_to ? item.assigned_to.toString()?.split('@')[0] : 'Not Assigned'}
                                        setData={(e: any) => e.id === 1 ? versionAssignToMe(item.id) : console.log(1)} styles={`pr-0 text-xs`}
                                    />
                                </div>
                            </td>
                            <td className={`whitespace-nowrap py-4 pr-3 text-xs w-[${100/8}%] ${item?.tat ? 'text-[#343A40]' : 'text-[#A4A4A4]'}`}>{item?.tat ?? ''}</td>
                            <td className={`whitespace-nowrap py-4 w-[${100/8}%]`}>
                                <button onClick={(e) => {
                                    setSelectedVersion({ item, flag: selectedVersion.flag ? true : false });
                                    e.stopPropagation();
                                    setExposurePanel(true);
                                }} className='px-4 py-1 rounded-full bg-primary-green hover:bg-opacity-60 transition-all duration-200 text-sm text-white'>Details</button>
                            </td>
                            <Tooltip id="assigned-to" arrowColor='#2D2D2D' style={{ color: '#f1f1f1' }} />
                        </tr>
                    ))}
                </CustomTable>
            </div>
            <RightSidePanel bottomClass="bottom-12" open={selectedVersion.flag} setOpen={() => setSelectedVersion({ item: undefined, flag: false })} selectedVersion={selectedVersion.item} controlNumber={controlNumber} />
            <PaginatedRecord
                totalPages={Math.ceil(total / 10)} total={total} listLength={versionList.original.length}
                onPageChange={(p) => setCurrentPage(p)} currentPage={currentPage} perPage={10}
                onDisplayChange={(n: number) => setVersionList({ ...versionList, original: versionList.copy.slice(0, n) })}
            />

            <ExposuresModal open={exposurePanel} isPendingSubmissionVersion={undefined} setOpen={setExposurePanel} selectedVersion={selectedVersion.item} controlNumber={controlNumber} />

            { showPremiumDialog.flag && (
                <DeleteOrAddPremiumModal status={{ id: showPremiumDialog.args.status_, value: showPremiumDialog.value }} onCloseModal={() => setShowPremiumDialog({
                    flag: false, args: { status_: 0, version: 0 }, value: ''
                })} onConfirm={(premium: number) => onPerformStatusUpdation(showPremiumDialog.args.status_, showPremiumDialog.args.version, true, premium)}>
                    <span>{""}</span>
                </DeleteOrAddPremiumModal>
            )}

        </React.Fragment>
    );
}

export default ApprovedVersions;